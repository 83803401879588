import { Server, Model } from "miragejs"

export function makeServer({ environment = "development" } = {}) {
  let server = new Server({
    environment,
    models: {
      listItems: Model,
    },
    fixtures: {
      listItems: [],
    },

    routes() {
      this.get(
        "http://localhost:1080/api/v1/plants/query",
        (schema, request) => {
          const result = []
          for (let i = 1; i <= 1000; i++) {
            if (i <= 10) {
              result.push({
                id: i,
                shopify_store_name: "butterlondon",
                shopify_order_id: "14671291261087",
                shopify_order_name: "410000077607",
                shopify_order_date: "2/21/2022 1:55:58 PM",
                shopify_email: "alemos@test.com",
                shopify_first_name: "Arlene",
                shopify_last_name: "Lemos",
                sage_sales_order_number: "4671291261087",
                sage_customer_number: "69999999",
              })
            } else {
              result.push({
                id: i,
                shopify_store_name: null,
                shopify_order_id: null,
                shopify_order_name: null,
                shopify_order_date: null,
                shopify_email: null,
                shopify_first_name: null,
                shopify_last_name: null,
                sage_sales_order_number: null,
                sage_customer_number: null,
              })
            }
          }
          return result
        },
        { timing: 500 }
      )
      this.get(
        "http://localhost:1080/api/v1/plants/search",
        (schema, request) => {
          const result = []
          for (let i = 1; i <= 1000; i++) {
            if (i <= 10) {
              result.push({
                id: i,
                shopify_store_name: "butterlondon",
                shopify_order_id: "14671291261087",
                shopify_order_name: "410000077607",
                shopify_order_date: "2/21/2022 1:55:58 PM",
                shopify_email: "alemos@test.com",
                shopify_first_name: "Arlene",
                shopify_last_name: "Lemos",
                sage_sales_order_number: "4671291261087",
                sage_customer_number: "69999999",
              })
            } else {
              result.push({
                id: i,
                shopify_store_name: null,
                shopify_order_id: null,
                shopify_order_name: null,
                shopify_order_date: null,
                shopify_email: null,
                shopify_first_name: null,
                shopify_last_name: null,
                sage_sales_order_number: null,
                sage_customer_number: null,
              })
            }
          }
          return result
        },
        { timing: 2000 }
      )
      this.post(
        "http://localhost:1080/api/v1/plants/get-plants",
        (schema, request) => {
          const result = JSON.parse(request.requestBody).map(item => ({
            id: item,
            shopify_store_name: "butterlondon" + item,
            shopify_order_id: "14671291261087",
            shopify_order_name: "410000077607",
            shopify_order_date: "2/21/2022 1:55:58 PM",
            shopify_email: "alemos@test.com",
            shopify_first_name: "Arlene",
            shopify_last_name: "Lemos",
            sage_sales_order_number: "4671291261087",
            sage_customer_number: "69999999",
          }))
          return result
        },
        { timing: 500 }
      )
      this.get(
        "http://localhost:1080/api/v1/dashboards/shopify",
        (schema, request) => {
          return {
            dashboard: [
              {
                id: 1,
                type: "scalar",
                displayType: "table",
                displayStyle: "donut",
                displayPallete: "Office",
                title: "order integration status",
                width: 4,
                icon: "shopping-cart",
                groups: ["butterlondon", "cosmedix", "lysbty", "purcosmetics"],
                subGroups: ["success", "failure", "n/a"],
                data: [
                  {
                    group: "butterlondon",
                    subGroup: "n/a",
                    value: 3,
                    plantType: "shopify_order",
                  },
                  {
                    group: "butterlondon",
                    subGroup: "success",
                    value: 509,
                    plantType: "shopify_order",
                  },
                  {
                    group: "cosmedix",
                    subGroup: "success",
                    value: 3,
                    plantType: "shopify_order",
                  },
                  {
                    group: "lysbty",
                    subGroup: "failure",
                    value: 1,
                    plantType: "shopify_order",
                  },
                  {
                    group: "lysbty",
                    subGroup: "success",
                    value: 5,
                    plantType: "shopify_order",
                  },
                  {
                    group: "purcosmetics",
                    subGroup: "failure",
                    value: 1,
                    plantType: "shopify_order",
                  },
                  {
                    group: "purcosmetics",
                    subGroup: "success",
                    value: 4,
                    plantType: "shopify_order",
                  },
                ],
              },
              {
                id: 7,
                type: "scalar",
                displayType: "table",
                displayStyle: "donut",
                displayPallete: "Office",
                title: "order payment integration status",
                width: 4,
                icon: "file-invoice-dollar",
                groups: ["butterlondon", "cosmedix", "lysbty", "purcosmetics"],
                subGroups: ["success", "failure", "n/a"],
                data: [
                  {
                    group: "butterlondon",
                    subGroup: "n/a",
                    value: 17,
                    plantType: "shopify_order",
                  },
                  {
                    group: "butterlondon",
                    subGroup: "success",
                    value: 492,
                    plantType: "shopify_order",
                  },
                  {
                    group: "cosmedix",
                    subGroup: "failure",
                    value: 3,
                    plantType: "shopify_order",
                  },
                  {
                    group: "lysbty",
                    subGroup: "failure",
                    value: 4,
                    plantType: "shopify_order",
                  },
                  {
                    group: "lysbty",
                    subGroup: "n/a",
                    value: 1,
                    plantType: "shopify_order",
                  },
                  {
                    group: "purcosmetics",
                    subGroup: "success",
                    value: 4,
                    plantType: "shopify_order",
                  },
                ],
              },
              {
                id: 8,
                type: "scalar",
                displayType: "table",
                displayStyle: "donut",
                displayPallete: "Office",
                title: "credit memo integration status",
                width: 4,
                icon: "money-check-alt",
                groups: ["butterlondon", "cosmedix", "lysbty", "purcosmetics"],
                subGroups: ["success", "failure", "n/a"],
                data: [
                  {
                    group: "butterlondon",
                    subGroup: "n/a",
                    value: 509,
                    plantType: "shopify_order",
                  },
                  {
                    group: "cosmedix",
                    subGroup: "n/a",
                    value: 3,
                    plantType: "shopify_order",
                  },
                  {
                    group: "lysbty",
                    subGroup: "n/a",
                    value: 5,
                    plantType: "shopify_order",
                  },
                  {
                    group: "purcosmetics",
                    subGroup: "n/a",
                    value: 4,
                    plantType: "shopify_order",
                  },
                ],
              },
              {
                id: 10,
                type: "scalar",
                displayType: "table",
                displayStyle: "donut",
                displayPallete: "Office",
                title: "order tracking number status",
                width: 4,
                icon: "truck",
                groups: ["butterlondon", "cosmedix", "lysbty", "purcosmetics"],
                subGroups: ["available", "missing"],
                data: [
                  {
                    group: "butterlondon",
                    subGroup: "available",
                    value: 452,
                    plantType: "shopify_order",
                  },
                  {
                    group: "butterlondon",
                    subGroup: "missing",
                    value: 57,
                    plantType: "shopify_order",
                  },
                  {
                    group: "cosmedix",
                    subGroup: "missing",
                    value: 3,
                    plantType: "shopify_order",
                  },
                  {
                    group: "lysbty",
                    subGroup: "missing",
                    value: 5,
                    plantType: "shopify_order",
                  },
                  {
                    group: "purcosmetics",
                    subGroup: "missing",
                    value: 4,
                    plantType: "shopify_order",
                  },
                ],
              },
              {
                id: 6,
                type: "scalar",
                displayType: "table",
                displayStyle: "donut",
                displayPallete: "Office",
                title: "inventory sku status",
                width: 4,
                icon: "warehouse",
                groups: ["butterlondon", "cosmedix", "lysbty", "purcosmetics"],
                subGroups: ["available", "missing"],
                data: [
                  {
                    group: "butterlondon",
                    subGroup: "available",
                    value: 272,
                    plantType: "shopify_inventory",
                  },
                  {
                    group: "butterlondon",
                    subGroup: "missing",
                    value: 4,
                    plantType: "shopify_inventory",
                  },
                  {
                    group: "cosmedix",
                    subGroup: "available",
                    value: 103,
                    plantType: "shopify_inventory",
                  },
                  {
                    group: "lysbty",
                    subGroup: "available",
                    value: 175,
                    plantType: "shopify_inventory",
                  },
                  {
                    group: "lysbty",
                    subGroup: "missing",
                    value: 4,
                    plantType: "shopify_inventory",
                  },
                  {
                    group: "purcosmetics",
                    subGroup: "available",
                    value: 366,
                    plantType: "shopify_inventory",
                  },
                  {
                    group: "purcosmetics",
                    subGroup: "missing",
                    value: 3,
                    plantType: "shopify_inventory",
                  },
                ],
              },
              {
                id: 13,
                type: "scalar",
                displayType: "table",
                displayStyle: "donut",
                displayPallete: "Office",
                title: "duplicated sku",
                width: 4,
                icon: "clone",
                groups: ["butterlondon", "cosmedix", "lysbty", "purcosmetics"],
                subGroups: [""],
                data: [
                  {
                    group: "butterlondon",
                    subGroup: "",
                    value: 14,
                    plantType: "shopify_inventory",
                  },
                  {
                    group: "purcosmetics",
                    subGroup: "",
                    value: 2,
                    plantType: "shopify_inventory",
                  },
                ],
              },
              {
                id: 2,
                type: "timeline",
                displayType: null,
                displayStyle: "area",
                displayPallete: "Office",
                title: "orders integrated into Sage",
                width: 6,
                icon: "shopping-cart",
                aggregationMethod: "sum",
                aggregationInterval: "day",
                groups: [
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T14:20:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T13:50:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T13:46:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T13:36:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T13:24:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T13:03:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T12:23:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T10:45:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T10:15:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T14:40:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T14:59:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T15:06:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T15:36:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T15:35:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T15:28:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T15:24:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T15:23:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T15:18:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T15:56:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T15:54:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T16:35:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T16:41:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T16:55:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T16:59:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T17:11:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T17:23:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T17:25:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T18:18:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T19:33:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T19:44:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T19:59:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T20:22:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T20:42:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T21:35:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T21:40:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T22:23:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T22:44:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T22:56:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T23:00:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T23:10:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T23:14:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T23:13:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T23:24:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T23:26:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T23:39:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T23:58:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T23:57:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T23:52:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T00:11:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T00:34:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T17:39:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T01:55:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T02:14:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T02:20:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T02:56:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T02:53:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T03:10:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T04:02:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T07:35:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T11:42:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T11:49:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T11:55:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T13:30:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T13:33:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T14:07:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T14:16:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T14:16:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T14:33:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T14:38:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T14:43:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T14:41:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T14:39:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T14:53:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T14:53:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T14:52:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T14:57:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T15:03:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T15:12:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T15:41:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T15:45:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T15:54:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T15:54:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T16:13:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T16:11:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T16:23:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T16:30:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T16:58:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T17:03:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T17:25:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T17:50:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T17:48:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T18:05:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T18:26:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T18:28:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T18:58:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T19:35:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T20:08:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T20:42:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T20:55:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T20:58:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T21:04:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T21:16:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T22:12:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T22:20:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T23:39:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T00:21:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T00:47:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T00:50:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T01:32:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T01:39:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T01:49:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T01:46:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T02:21:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T02:44:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T03:18:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T04:21:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T04:57:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T05:09:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T06:00:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T06:36:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T14:17:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T14:02:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T14:00:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T13:56:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T13:52:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T13:34:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T13:07:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T13:03:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T12:53:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T12:43:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T10:42:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T14:36:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T14:41:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T14:50:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T15:02:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T15:00:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T15:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T15:39:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T15:41:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T15:47:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T15:59:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T16:05:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T16:26:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T16:29:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T16:37:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T16:47:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T16:50:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T16:48:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T17:35:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T17:34:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T17:34:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T17:50:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T18:04:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T18:11:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T18:27:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T19:01:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T19:07:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T19:17:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T19:21:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T19:23:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T20:26:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T20:24:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T20:23:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T21:07:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T21:05:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T21:10:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T21:09:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T21:14:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T21:27:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T21:47:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T21:50:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T22:11:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T23:03:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T23:13:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T23:30:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T23:35:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T23:42:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T23:56:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T00:10:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T00:10:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T00:35:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T00:36:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T00:44:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T01:01:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T01:08:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T01:19:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T01:18:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T01:16:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T02:13:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T03:07:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T03:18:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T03:16:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T03:35:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T03:36:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T03:52:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T04:15:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T06:32:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T07:18:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T11:26:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T11:43:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T12:09:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T12:44:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T13:00:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T13:14:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T13:34:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T13:43:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T13:48:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T14:00:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T14:06:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T14:15:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T14:23:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T14:19:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T14:50:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T14:47:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T14:55:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T15:00:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T15:03:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T15:10:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T15:25:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T15:31:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T15:46:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T16:04:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T16:16:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T16:19:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T16:25:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T16:27:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T16:35:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T16:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T16:34:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T16:37:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T16:52:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T16:59:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T17:21:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T17:25:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T17:44:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T17:56:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T17:52:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T17:52:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T17:59:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T18:04:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T18:03:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T18:02:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T18:10:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T18:16:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T18:38:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T19:04:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T19:22:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T19:37:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T19:47:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T20:01:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T20:30:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T20:33:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T21:09:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T21:14:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T21:48:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T22:04:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T22:13:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T22:59:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T23:59:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T00:42:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T00:53:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T00:52:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T01:25:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T01:23:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T01:42:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T01:44:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T01:52:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T02:11:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T02:42:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T02:41:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T02:46:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T03:39:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T03:57:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T04:02:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T04:50:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T08:46:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T10:54:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T11:01:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T11:12:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T12:07:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T12:39:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T12:36:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T12:55:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T12:54:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T12:50:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T13:01:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T13:45:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T13:53:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T14:05:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T14:08:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T14:43:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T14:42:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T14:56:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T15:25:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T15:36:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T15:52:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T16:00:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T16:40:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T16:43:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T16:42:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T16:50:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T16:50:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T16:55:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T17:05:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T17:10:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T17:32:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T17:38:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T17:44:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T18:09:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T18:25:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T18:42:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T18:40:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T19:02:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T19:06:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T19:30:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T19:26:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T19:34:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T19:41:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T20:01:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T20:12:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T20:25:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T20:40:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T20:56:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T21:06:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T21:55:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T22:33:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T22:49:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T22:59:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T23:04:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T23:16:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T23:49:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T23:49:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T00:04:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T00:09:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T01:14:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T01:18:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T01:54:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T02:05:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T03:00:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T03:17:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T03:21:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T03:38:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T03:43:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T04:13:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T04:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T06:36:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T11:10:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T12:51:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T14:20:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T14:38:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T15:05:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T15:14:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T15:23:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T15:34:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T15:38:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T15:47:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T16:02:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T16:42:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T16:53:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T17:06:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T17:16:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T17:11:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T17:57:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T18:01:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T18:59:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T19:12:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T19:42:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T19:40:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T19:54:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T20:12:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T20:12:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T20:15:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T21:18:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T21:47:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T22:30:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T22:29:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T22:26:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T22:39:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T22:39:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T22:38:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T22:52:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T22:49:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T22:56:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T22:55:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T22:54:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T23:07:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T23:05:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T23:09:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T23:18:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T23:20:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T23:25:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T23:31:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T23:41:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T23:51:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T23:51:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T23:57:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T00:05:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T00:13:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T00:18:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T00:26:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T00:54:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T01:07:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T01:36:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T04:48:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T04:28:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T04:10:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T04:00:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T03:44:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T03:37:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T02:51:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T02:44:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T02:43:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T02:06:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T02:00:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T01:58:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T01:55:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T01:51:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T07:41:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T10:26:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T11:11:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T12:13:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T12:25:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T12:36:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T12:58:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T13:22:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T13:21:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T13:30:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T13:37:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T13:43:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T13:43:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T13:48:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T13:58:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T14:03:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T14:21:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T14:33:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T14:43:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T14:43:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T15:15:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T15:15:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T15:13:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T15:28:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T15:52:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T16:10:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T16:17:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T16:26:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T16:24:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T16:46:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T17:08:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T17:24:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T17:44:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T17:50:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T18:04:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T18:02:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T18:12:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T18:43:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T19:01:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T18:58:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T18:57:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T19:04:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T19:40:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T19:39:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T19:46:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T19:48:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T19:54:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T20:02:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T20:13:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T20:51:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T21:10:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T21:09:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T22:03:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T22:16:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T22:19:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T22:28:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T23:11:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T23:40:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T23:44:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T23:44:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T23:49:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T23:48:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T23:53:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T00:29:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T00:36:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T00:56:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T01:03:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T01:26:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T02:27:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T03:23:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T03:31:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T03:31:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T07:45:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T09:29:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T10:11:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T10:38:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T11:24:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T11:50:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T12:11:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T13:06:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T14:37:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T15:08:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T15:08:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T15:19:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T15:29:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T16:21:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T16:26:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T16:25:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T16:31:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T16:34:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T17:31:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T17:37:45.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 1,
                    timestamp: "2022-04-06T14:06:38.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 1,
                    timestamp: "2022-04-06T14:09:13.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 1,
                    timestamp: "2022-04-06T14:12:47.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 1,
                    timestamp: "2022-04-04T23:41:01.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 1,
                    timestamp: "2022-04-05T05:08:20.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 1,
                    timestamp: "2022-04-01T14:52:50.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 1,
                    timestamp: "2022-03-31T21:23:13.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 1,
                    timestamp: "2022-04-05T06:54:16.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 1,
                    timestamp: "2022-04-05T15:05:19.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 1,
                    timestamp: "2022-04-05T00:07:19.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 1,
                    timestamp: "2022-04-04T21:59:50.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 1,
                    timestamp: "2022-04-05T00:51:29.000Z",
                  },
                ],
              },
              {
                id: 21,
                type: "timeline",
                displayType: null,
                displayStyle: "area",
                displayPallete: "Office",
                title: "orders integrated into Sage (past 24 hours)",
                width: 6,
                icon: "shopping-cart",
                aggregationMethod: "sum",
                aggregationInterval: "hour",
                groups: [
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T17:08:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T17:24:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T17:44:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T17:50:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T18:04:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T18:02:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T18:12:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T18:43:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T19:01:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T18:58:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T18:57:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T19:04:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T19:40:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T19:39:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T19:46:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T19:48:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T19:54:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T20:02:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T20:13:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T20:51:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T21:10:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T21:09:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T22:03:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T22:16:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T22:19:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T22:28:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T23:11:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T23:40:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T23:44:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T23:44:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T23:49:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T23:48:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T23:53:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T00:29:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T00:36:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T00:56:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T01:03:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T01:26:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T02:27:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T03:23:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T03:31:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T03:31:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T07:45:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T09:29:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T10:11:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T10:38:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T11:24:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T11:50:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T12:11:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T13:06:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T14:37:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T15:08:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T15:08:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T15:19:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T15:29:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T16:21:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T16:26:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T16:25:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T16:31:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T16:34:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T17:31:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T17:37:45.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 1,
                    timestamp: "2022-04-06T14:06:38.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 1,
                    timestamp: "2022-04-06T14:09:13.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 1,
                    timestamp: "2022-04-06T14:12:47.000Z",
                  },
                ],
              },
              {
                id: 20,
                type: "timeline",
                displayType: null,
                displayStyle: "bar",
                displayPallete: "Office",
                title: "orders integrated into Sage - line count",
                width: 4,
                icon: "shopping-cart",
                aggregationMethod: "avg",
                aggregationInterval: "day",
                groups: [
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-03-30T14:20:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-30T13:50:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-30T13:46:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T13:36:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-03-30T13:24:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-30T13:03:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T12:23:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T10:45:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T10:15:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-03-30T14:40:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-03-30T14:59:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T15:06:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-30T15:36:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6,
                    timestamp: "2022-03-30T15:35:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T15:28:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T15:24:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-03-30T15:23:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T15:18:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-03-30T15:56:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-30T15:54:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T16:35:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-30T16:41:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T16:55:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T16:59:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-03-30T17:11:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-03-30T17:23:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-03-30T17:25:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-30T18:18:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T19:33:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-30T19:44:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-03-30T19:59:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T20:22:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-30T20:42:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T21:35:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-03-30T21:40:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-03-30T22:23:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T22:44:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-03-30T22:56:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-03-30T23:00:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-03-30T23:10:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-30T23:14:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-03-30T23:13:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 7,
                    timestamp: "2022-03-30T23:24:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-03-30T23:26:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-03-30T23:39:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 8,
                    timestamp: "2022-03-30T23:58:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-03-30T23:57:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-30T23:52:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-03-31T00:11:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T00:34:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-03-30T17:39:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T01:55:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6,
                    timestamp: "2022-03-31T02:14:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-03-31T02:20:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T02:56:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T02:53:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T03:10:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T04:02:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T07:35:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-31T11:42:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-03-31T11:49:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T11:55:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-31T13:30:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T13:33:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T14:07:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-31T14:16:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-03-31T14:16:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-03-31T14:33:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 7,
                    timestamp: "2022-03-31T14:38:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-31T14:43:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T14:41:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T14:39:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-31T14:53:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T14:53:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-03-31T14:52:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-03-31T14:57:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T15:03:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-31T15:12:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-03-31T15:41:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T15:45:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-03-31T15:54:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-31T15:54:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T16:13:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-03-31T16:11:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T16:23:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T16:30:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T16:58:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-31T17:03:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-31T17:25:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-03-31T17:50:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T17:48:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-03-31T18:05:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-03-31T18:26:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-03-31T18:28:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-03-31T18:58:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T19:35:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-03-31T20:08:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-03-31T20:42:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 12,
                    timestamp: "2022-03-31T20:55:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T20:58:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-03-31T21:04:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T21:16:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T22:12:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-03-31T22:20:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6,
                    timestamp: "2022-03-31T23:39:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T00:21:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-01T00:47:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-01T00:50:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-01T01:32:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T01:39:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T01:49:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6,
                    timestamp: "2022-04-01T01:46:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-01T02:21:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-01T02:44:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-01T03:18:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T04:21:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T04:57:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T05:09:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-01T06:00:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-01T06:36:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T14:17:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-01T14:02:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T14:00:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-01T13:56:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-01T13:52:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-01T13:34:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-01T13:07:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T13:03:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-01T12:53:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T12:43:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-01T10:42:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T14:36:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-01T14:41:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-01T14:50:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T15:02:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-01T15:00:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-01T15:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T15:39:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T15:41:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T15:47:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-01T15:59:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-01T16:05:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T16:26:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T16:29:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T16:37:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T16:47:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T16:50:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T16:48:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T17:35:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-01T17:34:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-01T17:34:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-01T17:50:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T18:04:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T18:11:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-01T18:27:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T19:01:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T19:07:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6,
                    timestamp: "2022-04-01T19:17:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T19:21:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-01T19:23:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-01T20:26:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T20:24:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-01T20:23:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-01T21:07:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-01T21:05:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-01T21:10:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-01T21:09:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T21:14:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-01T21:27:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-01T21:47:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-01T21:50:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T22:11:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 7,
                    timestamp: "2022-04-01T23:03:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-01T23:13:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-01T23:30:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T23:35:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-01T23:42:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-01T23:56:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-02T00:10:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-02T00:10:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 7,
                    timestamp: "2022-04-02T00:35:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T00:36:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T00:44:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T01:01:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-02T01:08:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T01:19:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T01:18:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6,
                    timestamp: "2022-04-02T01:16:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T02:13:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T03:07:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T03:18:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-02T03:16:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-02T03:35:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-02T03:36:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6,
                    timestamp: "2022-04-02T03:52:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6,
                    timestamp: "2022-04-02T04:15:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T06:32:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-02T07:18:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-02T11:26:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T11:43:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-02T12:09:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-02T12:44:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-02T13:00:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T13:14:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-02T13:34:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T13:43:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T13:48:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T14:00:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-02T14:06:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T14:15:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-02T14:23:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-02T14:19:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-02T14:50:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T14:47:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-02T14:55:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T15:00:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T15:03:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-02T15:10:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-02T15:25:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-02T15:31:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 7,
                    timestamp: "2022-04-02T15:46:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-02T16:04:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T16:16:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-02T16:19:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-02T16:25:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-02T16:27:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 7,
                    timestamp: "2022-04-02T16:35:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T16:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-02T16:34:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T16:37:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-02T16:52:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-02T16:59:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-02T17:21:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-02T17:25:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T17:44:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-02T17:56:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-02T17:52:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T17:52:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-02T17:59:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-02T18:04:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T18:03:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-02T18:02:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-02T18:10:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T18:16:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T18:38:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-02T19:04:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T19:22:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-02T19:37:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-04-02T19:47:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T20:01:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-02T20:30:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-02T20:33:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-02T21:09:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T21:14:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-02T21:48:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T22:04:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-02T22:13:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-02T22:59:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-02T23:59:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-03T00:42:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T00:53:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T00:52:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T01:25:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-03T01:23:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-03T01:42:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T01:44:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-03T01:52:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T02:11:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-03T02:42:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T02:41:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T02:46:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-03T03:39:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-04-03T03:57:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-03T04:02:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-03T04:50:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-03T08:46:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T10:54:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T11:01:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T11:12:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-03T12:07:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-03T12:39:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-03T12:36:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T12:55:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T12:54:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T12:50:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T13:01:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-03T13:45:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-03T13:53:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-03T14:05:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T14:08:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-03T14:43:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T14:42:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-03T14:56:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T15:25:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T15:36:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-03T15:52:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-03T16:00:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-03T16:40:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T16:43:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-03T16:42:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-03T16:50:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-03T16:50:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-03T16:55:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-03T17:05:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-03T17:10:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-03T17:32:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 12,
                    timestamp: "2022-04-03T17:38:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-03T17:44:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T18:09:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-03T18:25:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-03T18:42:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6,
                    timestamp: "2022-04-03T18:40:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-03T19:02:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T19:06:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-03T19:30:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-03T19:26:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-03T19:34:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-03T19:41:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-03T20:01:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T20:12:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-03T20:25:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-03T20:40:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T20:56:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-03T21:06:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-03T21:55:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-03T22:33:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T22:49:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-03T22:59:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-03T23:04:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-03T23:16:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-03T23:49:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-03T23:49:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T00:04:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-04T00:09:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-04T01:14:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T01:18:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T01:54:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T02:05:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-04T03:00:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-04T03:17:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 7,
                    timestamp: "2022-04-04T03:21:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-04T03:38:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T03:43:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-04T04:13:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 12,
                    timestamp: "2022-04-04T04:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-04T06:36:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T11:10:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T12:51:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-04T14:20:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T14:38:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-04T15:05:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-04T15:14:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-04T15:23:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6,
                    timestamp: "2022-04-04T15:34:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-04T15:38:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-04T15:47:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T16:02:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-04T16:42:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T16:53:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-04T17:06:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T17:16:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-04T17:11:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-04T17:57:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T18:01:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-04T18:59:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-04T19:12:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T19:42:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T19:40:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-04T19:54:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-04T20:12:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T20:12:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-04T20:15:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-04T21:18:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T21:47:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-04T22:30:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T22:29:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-04T22:26:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T22:39:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T22:39:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-04T22:38:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T22:52:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-04T22:49:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-04T22:56:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T22:55:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-04T22:54:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 18,
                    timestamp: "2022-04-04T23:07:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T23:05:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T23:09:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-04T23:18:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-04T23:20:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T23:25:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-04T23:31:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T23:41:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-04T23:51:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T23:51:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-04T23:57:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-05T00:05:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T00:13:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T00:18:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T00:26:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-05T00:54:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T01:07:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-05T01:36:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T04:48:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-05T04:28:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-05T04:10:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-05T04:00:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-04-05T03:44:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-05T03:37:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-05T02:51:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T02:44:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-05T02:43:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-05T02:06:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T02:00:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-05T01:58:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T01:55:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-05T01:51:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T07:41:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T10:26:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-05T11:11:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-05T12:13:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T12:25:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-05T12:36:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-05T12:58:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T13:22:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 7,
                    timestamp: "2022-04-05T13:21:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T13:30:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T13:37:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T13:43:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T13:43:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T13:48:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-05T13:58:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T14:03:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T14:21:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-05T14:33:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-05T14:43:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T14:43:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-05T15:15:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T15:15:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-05T15:13:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-05T15:28:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T15:52:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T16:10:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-05T16:17:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-05T16:26:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T16:24:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T16:46:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-05T17:08:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6,
                    timestamp: "2022-04-05T17:24:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-05T17:44:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-05T17:50:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-05T18:04:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T18:02:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T18:12:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-05T18:43:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T19:01:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T18:58:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-05T18:57:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-05T19:04:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-05T19:40:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T19:39:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-05T19:46:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T19:48:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T19:54:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-05T20:02:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T20:13:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-05T20:51:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-05T21:10:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-05T21:09:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-05T22:03:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-05T22:16:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-05T22:19:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-05T22:28:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-05T23:11:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T23:40:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6,
                    timestamp: "2022-04-05T23:44:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-05T23:44:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-05T23:49:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-05T23:48:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-05T23:53:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-06T00:29:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-06T00:36:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-06T00:56:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-06T01:03:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-06T01:26:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-06T02:27:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-06T03:23:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T03:31:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T03:31:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-06T07:45:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T09:29:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-06T10:11:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-06T10:38:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T11:24:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T11:50:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T12:11:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-06T13:06:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T14:37:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-06T15:08:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-06T15:08:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-06T15:19:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-06T15:29:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-06T16:21:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T16:26:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5,
                    timestamp: "2022-04-06T16:25:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T16:31:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-06T16:34:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3,
                    timestamp: "2022-04-06T17:31:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1,
                    timestamp: "2022-04-06T17:37:45.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 1,
                    timestamp: "2022-04-06T14:06:38.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 1,
                    timestamp: "2022-04-06T14:09:13.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 1,
                    timestamp: "2022-04-06T14:12:47.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 7,
                    timestamp: "2022-04-04T23:41:01.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 7,
                    timestamp: "2022-04-05T05:08:20.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 4,
                    timestamp: "2022-04-01T14:52:50.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 1,
                    timestamp: "2022-03-31T21:23:13.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 4,
                    timestamp: "2022-04-05T06:54:16.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 1,
                    timestamp: "2022-04-05T15:05:19.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 4,
                    timestamp: "2022-04-05T00:07:19.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 1,
                    timestamp: "2022-04-04T21:59:50.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 1,
                    timestamp: "2022-04-05T00:51:29.000Z",
                  },
                ],
              },
              {
                id: 15,
                type: "timeline",
                displayType: null,
                displayStyle: "line",
                displayPallete: "Office",
                title: "orders integrated into Sage - total amount",
                width: 4,
                icon: "shopping-cart",
                aggregationMethod: "avg",
                aggregationInterval: "day",
                groups: [
                  {
                    group: "butterlondon",
                    value: 65.28,
                    timestamp: "2022-03-30T14:20:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 37.95,
                    timestamp: "2022-03-30T13:50:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 30.95,
                    timestamp: "2022-03-30T13:46:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 27.01,
                    timestamp: "2022-03-30T13:36:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 61.7,
                    timestamp: "2022-03-30T13:24:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 58.95,
                    timestamp: "2022-03-30T13:03:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 23.25,
                    timestamp: "2022-03-30T12:23:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.45,
                    timestamp: "2022-03-30T10:45:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 46.28,
                    timestamp: "2022-03-30T10:15:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 61.5,
                    timestamp: "2022-03-30T14:40:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 57.6,
                    timestamp: "2022-03-30T14:59:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 12.14,
                    timestamp: "2022-03-30T15:06:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.55,
                    timestamp: "2022-03-30T15:36:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60,
                    timestamp: "2022-03-30T15:35:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 44.85,
                    timestamp: "2022-03-30T15:28:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 46.95,
                    timestamp: "2022-03-30T15:24:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 58,
                    timestamp: "2022-03-30T15:23:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.45,
                    timestamp: "2022-03-30T15:18:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 48.99,
                    timestamp: "2022-03-30T15:56:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 48.95,
                    timestamp: "2022-03-30T15:54:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-03-30T16:35:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 57,
                    timestamp: "2022-03-30T16:41:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 33.7,
                    timestamp: "2022-03-30T16:55:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-03-30T16:59:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 76.32,
                    timestamp: "2022-03-30T17:11:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 72.37,
                    timestamp: "2022-03-30T17:23:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 59.53,
                    timestamp: "2022-03-30T17:25:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 46.39,
                    timestamp: "2022-03-30T18:18:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.7,
                    timestamp: "2022-03-30T19:33:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 37.13,
                    timestamp: "2022-03-30T19:44:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 74.4,
                    timestamp: "2022-03-30T19:59:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-03-30T20:22:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 54.36,
                    timestamp: "2022-03-30T20:42:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 20.21,
                    timestamp: "2022-03-30T21:35:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 56,
                    timestamp: "2022-03-30T21:40:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 48.95,
                    timestamp: "2022-03-30T22:23:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-03-30T22:44:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 78.44,
                    timestamp: "2022-03-30T22:56:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 57.6,
                    timestamp: "2022-03-30T23:00:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.2,
                    timestamp: "2022-03-30T23:10:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-03-30T23:14:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 78.4,
                    timestamp: "2022-03-30T23:13:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 79.96,
                    timestamp: "2022-03-30T23:24:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 212.21,
                    timestamp: "2022-03-30T23:26:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 66,
                    timestamp: "2022-03-30T23:39:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 115.2,
                    timestamp: "2022-03-30T23:58:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 66.11,
                    timestamp: "2022-03-30T23:57:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 27.01,
                    timestamp: "2022-03-30T23:52:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 99.17,
                    timestamp: "2022-03-31T00:11:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-03-31T00:34:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 63,
                    timestamp: "2022-03-30T17:39:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.7,
                    timestamp: "2022-03-31T01:55:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 62.1,
                    timestamp: "2022-03-31T02:14:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 79,
                    timestamp: "2022-03-31T02:20:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.35,
                    timestamp: "2022-03-31T02:56:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 21.95,
                    timestamp: "2022-03-31T02:53:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-03-31T03:10:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-03-31T04:02:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-03-31T07:35:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 53.75,
                    timestamp: "2022-03-31T11:42:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 64.2,
                    timestamp: "2022-03-31T11:49:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.95,
                    timestamp: "2022-03-31T11:55:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-03-31T13:30:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.95,
                    timestamp: "2022-03-31T13:33:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-03-31T14:07:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 78,
                    timestamp: "2022-03-31T14:16:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-03-31T14:16:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.95,
                    timestamp: "2022-03-31T14:33:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 67.2,
                    timestamp: "2022-03-31T14:38:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 52.95,
                    timestamp: "2022-03-31T14:43:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.95,
                    timestamp: "2022-03-31T14:41:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.35,
                    timestamp: "2022-03-31T14:39:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 54.95,
                    timestamp: "2022-03-31T14:53:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-03-31T14:53:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 64.66,
                    timestamp: "2022-03-31T14:52:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 54.65,
                    timestamp: "2022-03-31T14:57:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 28.8,
                    timestamp: "2022-03-31T15:03:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 37.83,
                    timestamp: "2022-03-31T15:12:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T15:41:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-03-31T15:45:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 63.15,
                    timestamp: "2022-03-31T15:54:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 40.43,
                    timestamp: "2022-03-31T15:54:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-03-31T16:13:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 57.35,
                    timestamp: "2022-03-31T16:11:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.62,
                    timestamp: "2022-03-31T16:23:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 22.09,
                    timestamp: "2022-03-31T16:30:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-03-31T16:58:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 29.45,
                    timestamp: "2022-03-31T17:03:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 46.77,
                    timestamp: "2022-03-31T17:25:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 59.64,
                    timestamp: "2022-03-31T17:50:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.7,
                    timestamp: "2022-03-31T17:48:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.95,
                    timestamp: "2022-03-31T18:05:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 64.98,
                    timestamp: "2022-03-31T18:26:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 61.4,
                    timestamp: "2022-03-31T18:28:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 63.77,
                    timestamp: "2022-03-31T18:58:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-03-31T19:35:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 58.95,
                    timestamp: "2022-03-31T20:08:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.31,
                    timestamp: "2022-03-31T20:42:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 139.5,
                    timestamp: "2022-03-31T20:55:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-03-31T20:58:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 55.85,
                    timestamp: "2022-03-31T21:04:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 28.85,
                    timestamp: "2022-03-31T21:16:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 27,
                    timestamp: "2022-03-31T22:12:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-03-31T22:20:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 103.89,
                    timestamp: "2022-03-31T23:39:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-01T00:21:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.95,
                    timestamp: "2022-04-01T00:47:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-04-01T00:50:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 78.4,
                    timestamp: "2022-04-01T01:32:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 56.24,
                    timestamp: "2022-04-01T01:39:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 59.48,
                    timestamp: "2022-04-01T01:49:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 115.99,
                    timestamp: "2022-04-01T01:46:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 46.5,
                    timestamp: "2022-04-01T02:21:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 45.75,
                    timestamp: "2022-04-01T02:44:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 66,
                    timestamp: "2022-04-01T03:18:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 61.28,
                    timestamp: "2022-04-01T04:21:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-01T04:57:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.67,
                    timestamp: "2022-04-01T05:09:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 78.8,
                    timestamp: "2022-04-01T06:00:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 63.5,
                    timestamp: "2022-04-01T06:36:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-01T14:17:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 59.75,
                    timestamp: "2022-04-01T14:02:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 27.14,
                    timestamp: "2022-04-01T14:00:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 67.2,
                    timestamp: "2022-04-01T13:56:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 57.6,
                    timestamp: "2022-04-01T13:52:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 36.34,
                    timestamp: "2022-04-01T13:34:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 89.46,
                    timestamp: "2022-04-01T13:07:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-01T13:03:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-04-01T12:53:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-01T12:43:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 109.98,
                    timestamp: "2022-04-01T10:42:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-01T14:36:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-04-01T14:41:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 46.5,
                    timestamp: "2022-04-01T14:50:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-01T15:02:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 65.26,
                    timestamp: "2022-04-01T15:00:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 85.86,
                    timestamp: "2022-04-01T15:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T15:39:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 27.17,
                    timestamp: "2022-04-01T15:41:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.82,
                    timestamp: "2022-04-01T15:47:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 47.45,
                    timestamp: "2022-04-01T15:59:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 110,
                    timestamp: "2022-04-01T16:05:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 50.95,
                    timestamp: "2022-04-01T16:26:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 28.95,
                    timestamp: "2022-04-01T16:29:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-01T16:37:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 27.01,
                    timestamp: "2022-04-01T16:47:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-01T16:50:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 46.49,
                    timestamp: "2022-04-01T16:48:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-01T17:35:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 62.07,
                    timestamp: "2022-04-01T17:34:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 51.02,
                    timestamp: "2022-04-01T17:34:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 65.82,
                    timestamp: "2022-04-01T17:50:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 34.95,
                    timestamp: "2022-04-01T18:04:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.63,
                    timestamp: "2022-04-01T18:11:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 84,
                    timestamp: "2022-04-01T18:27:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T19:01:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.35,
                    timestamp: "2022-04-01T19:07:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 111.3,
                    timestamp: "2022-04-01T19:17:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.7,
                    timestamp: "2022-04-01T19:21:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 58.36,
                    timestamp: "2022-04-01T19:23:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 39.99,
                    timestamp: "2022-04-01T20:26:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 150,
                    timestamp: "2022-04-01T20:24:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 33.95,
                    timestamp: "2022-04-01T20:23:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 40.45,
                    timestamp: "2022-04-01T21:07:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 56.25,
                    timestamp: "2022-04-01T21:05:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 46.95,
                    timestamp: "2022-04-01T21:10:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 56.45,
                    timestamp: "2022-04-01T21:09:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 27.01,
                    timestamp: "2022-04-01T21:14:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 55.8,
                    timestamp: "2022-04-01T21:27:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 61.5,
                    timestamp: "2022-04-01T21:47:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.95,
                    timestamp: "2022-04-01T21:50:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 27.02,
                    timestamp: "2022-04-01T22:11:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 128.79,
                    timestamp: "2022-04-01T23:03:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 99.51,
                    timestamp: "2022-04-01T23:13:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 58.83,
                    timestamp: "2022-04-01T23:30:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-04-01T23:35:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 40.1,
                    timestamp: "2022-04-01T23:42:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 36.95,
                    timestamp: "2022-04-01T23:56:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.95,
                    timestamp: "2022-04-02T00:10:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 46.56,
                    timestamp: "2022-04-02T00:10:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 96,
                    timestamp: "2022-04-02T00:35:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 45.96,
                    timestamp: "2022-04-02T00:36:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 48.99,
                    timestamp: "2022-04-02T00:44:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.7,
                    timestamp: "2022-04-02T01:01:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 63,
                    timestamp: "2022-04-02T01:08:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-02T01:19:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60,
                    timestamp: "2022-04-02T01:18:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 64,
                    timestamp: "2022-04-02T01:16:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-02T02:13:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-02T03:07:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-02T03:18:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 47.45,
                    timestamp: "2022-04-02T03:16:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 58,
                    timestamp: "2022-04-02T03:35:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 30.95,
                    timestamp: "2022-04-02T03:36:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 151.06,
                    timestamp: "2022-04-02T03:52:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 97.05,
                    timestamp: "2022-04-02T04:15:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-02T06:32:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 36.95,
                    timestamp: "2022-04-02T07:18:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-04-02T11:26:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 48.99,
                    timestamp: "2022-04-02T11:43:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 67.55,
                    timestamp: "2022-04-02T12:09:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 96,
                    timestamp: "2022-04-02T12:44:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 72,
                    timestamp: "2022-04-02T13:00:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-02T13:14:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 87.48,
                    timestamp: "2022-04-02T13:34:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-02T13:43:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-02T13:48:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-02T14:00:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 96.14,
                    timestamp: "2022-04-02T14:06:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-02T14:15:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-04-02T14:23:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 69.75,
                    timestamp: "2022-04-02T14:19:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 52.21,
                    timestamp: "2022-04-02T14:50:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 30.95,
                    timestamp: "2022-04-02T14:47:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 102.72,
                    timestamp: "2022-04-02T14:55:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 19.67,
                    timestamp: "2022-04-02T15:00:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 30.95,
                    timestamp: "2022-04-02T15:03:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 55,
                    timestamp: "2022-04-02T15:10:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 64.2,
                    timestamp: "2022-04-02T15:25:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 63,
                    timestamp: "2022-04-02T15:31:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 115.2,
                    timestamp: "2022-04-02T15:46:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 59.84,
                    timestamp: "2022-04-02T16:04:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-04-02T16:16:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 63.7,
                    timestamp: "2022-04-02T16:19:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60,
                    timestamp: "2022-04-02T16:25:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 59.92,
                    timestamp: "2022-04-02T16:27:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 132,
                    timestamp: "2022-04-02T16:35:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.81,
                    timestamp: "2022-04-02T16:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 81.97,
                    timestamp: "2022-04-02T16:34:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-02T16:37:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60,
                    timestamp: "2022-04-02T16:52:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 67.41,
                    timestamp: "2022-04-02T16:59:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 55,
                    timestamp: "2022-04-02T17:21:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 74,
                    timestamp: "2022-04-02T17:25:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 27.25,
                    timestamp: "2022-04-02T17:44:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 63.89,
                    timestamp: "2022-04-02T17:56:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 72.93,
                    timestamp: "2022-04-02T17:52:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 30.95,
                    timestamp: "2022-04-02T17:52:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 64.66,
                    timestamp: "2022-04-02T17:59:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 92.65,
                    timestamp: "2022-04-02T18:04:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-04-02T18:03:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 63.09,
                    timestamp: "2022-04-02T18:02:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 39.73,
                    timestamp: "2022-04-02T18:10:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 32.88,
                    timestamp: "2022-04-02T18:16:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.6,
                    timestamp: "2022-04-02T18:38:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 77.01,
                    timestamp: "2022-04-02T19:04:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 30.95,
                    timestamp: "2022-04-02T19:22:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 62.43,
                    timestamp: "2022-04-02T19:37:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 176.4,
                    timestamp: "2022-04-02T19:47:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-02T20:01:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 56.5,
                    timestamp: "2022-04-02T20:30:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.95,
                    timestamp: "2022-04-02T20:33:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 45.53,
                    timestamp: "2022-04-02T21:09:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-02T21:14:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.95,
                    timestamp: "2022-04-02T21:48:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-02T22:04:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-04-02T22:13:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-02T22:59:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 45.53,
                    timestamp: "2022-04-02T23:59:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 61.38,
                    timestamp: "2022-04-03T00:42:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 27.01,
                    timestamp: "2022-04-03T00:53:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.45,
                    timestamp: "2022-04-03T00:52:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-03T01:25:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.95,
                    timestamp: "2022-04-03T01:23:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 90.53,
                    timestamp: "2022-04-03T01:42:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-03T01:44:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-04-03T01:52:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-03T02:11:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 36.49,
                    timestamp: "2022-04-03T02:42:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.7,
                    timestamp: "2022-04-03T02:41:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.95,
                    timestamp: "2022-04-03T02:46:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 59.81,
                    timestamp: "2022-04-03T03:39:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 139.59,
                    timestamp: "2022-04-03T03:57:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.95,
                    timestamp: "2022-04-03T04:02:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 29.62,
                    timestamp: "2022-04-03T04:50:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 32.81,
                    timestamp: "2022-04-03T08:46:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-03T10:54:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-03T11:01:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 27.12,
                    timestamp: "2022-04-03T11:12:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-04-03T12:07:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 58.84,
                    timestamp: "2022-04-03T12:39:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 65.27,
                    timestamp: "2022-04-03T12:36:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.57,
                    timestamp: "2022-04-03T12:55:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-03T12:54:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-03T12:50:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 45.96,
                    timestamp: "2022-04-03T13:01:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 64.96,
                    timestamp: "2022-04-03T13:45:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 84.64,
                    timestamp: "2022-04-03T13:53:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 74.65,
                    timestamp: "2022-04-03T14:05:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 64.2,
                    timestamp: "2022-04-03T14:08:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 50.95,
                    timestamp: "2022-04-03T14:43:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.51,
                    timestamp: "2022-04-03T14:42:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 55.5,
                    timestamp: "2022-04-03T14:56:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 27.19,
                    timestamp: "2022-04-03T15:25:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T15:36:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-04-03T15:52:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 72,
                    timestamp: "2022-04-03T16:00:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 135.78,
                    timestamp: "2022-04-03T16:40:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-03T16:43:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 84,
                    timestamp: "2022-04-03T16:42:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 127.2,
                    timestamp: "2022-04-03T16:50:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 63.21,
                    timestamp: "2022-04-03T16:50:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 59.75,
                    timestamp: "2022-04-03T16:55:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 47.29,
                    timestamp: "2022-04-03T17:05:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.95,
                    timestamp: "2022-04-03T17:10:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 45.95,
                    timestamp: "2022-04-03T17:32:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 174.75,
                    timestamp: "2022-04-03T17:38:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 64.66,
                    timestamp: "2022-04-03T17:44:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.35,
                    timestamp: "2022-04-03T18:09:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 57.6,
                    timestamp: "2022-04-03T18:25:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 77.96,
                    timestamp: "2022-04-03T18:42:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 77.04,
                    timestamp: "2022-04-03T18:40:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 51.89,
                    timestamp: "2022-04-03T19:02:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.6,
                    timestamp: "2022-04-03T19:06:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 47.58,
                    timestamp: "2022-04-03T19:30:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 59.48,
                    timestamp: "2022-04-03T19:26:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 57.6,
                    timestamp: "2022-04-03T19:34:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 67.12,
                    timestamp: "2022-04-03T19:41:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 33.95,
                    timestamp: "2022-04-03T20:01:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-03T20:12:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 66.07,
                    timestamp: "2022-04-03T20:25:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 72,
                    timestamp: "2022-04-03T20:40:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.45,
                    timestamp: "2022-04-03T20:56:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.95,
                    timestamp: "2022-04-03T21:06:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 57,
                    timestamp: "2022-04-03T21:55:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 58.4,
                    timestamp: "2022-04-03T22:33:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.7,
                    timestamp: "2022-04-03T22:49:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 63,
                    timestamp: "2022-04-03T22:59:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 69.29,
                    timestamp: "2022-04-03T23:04:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 71.13,
                    timestamp: "2022-04-03T23:16:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-04-03T23:49:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.95,
                    timestamp: "2022-04-03T23:49:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-04-04T00:04:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 58.53,
                    timestamp: "2022-04-04T00:09:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 33.95,
                    timestamp: "2022-04-04T01:14:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 45.8,
                    timestamp: "2022-04-04T01:18:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.45,
                    timestamp: "2022-04-04T01:54:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 27.12,
                    timestamp: "2022-04-04T02:05:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-04-04T03:00:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 63,
                    timestamp: "2022-04-04T03:17:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 136.92,
                    timestamp: "2022-04-04T03:21:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 62.85,
                    timestamp: "2022-04-04T03:38:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 27.01,
                    timestamp: "2022-04-04T03:43:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 61.56,
                    timestamp: "2022-04-04T04:13:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 214,
                    timestamp: "2022-04-04T04:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 45.56,
                    timestamp: "2022-04-04T06:36:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 30.95,
                    timestamp: "2022-04-04T11:10:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-04T12:51:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 282,
                    timestamp: "2022-04-04T14:20:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T14:38:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 108.27,
                    timestamp: "2022-04-04T15:05:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 49.95,
                    timestamp: "2022-04-04T15:14:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 33.12,
                    timestamp: "2022-04-04T15:23:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 140,
                    timestamp: "2022-04-04T15:34:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 33.95,
                    timestamp: "2022-04-04T15:38:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 35.75,
                    timestamp: "2022-04-04T15:47:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-04T16:02:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60,
                    timestamp: "2022-04-04T16:42:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 28.09,
                    timestamp: "2022-04-04T16:53:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 69.55,
                    timestamp: "2022-04-04T17:06:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-04T17:16:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 77.04,
                    timestamp: "2022-04-04T17:11:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 91.41,
                    timestamp: "2022-04-04T17:57:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.95,
                    timestamp: "2022-04-04T18:01:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 76.68,
                    timestamp: "2022-04-04T18:59:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 45.64,
                    timestamp: "2022-04-04T19:12:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.31,
                    timestamp: "2022-04-04T19:42:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 30.98,
                    timestamp: "2022-04-04T19:40:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 56.95,
                    timestamp: "2022-04-04T19:54:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 21.95,
                    timestamp: "2022-04-04T20:12:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-04T20:12:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.65,
                    timestamp: "2022-04-04T20:15:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 33.5,
                    timestamp: "2022-04-04T21:18:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.95,
                    timestamp: "2022-04-04T21:47:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 59,
                    timestamp: "2022-04-04T22:30:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 49.66,
                    timestamp: "2022-04-04T22:29:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 66.78,
                    timestamp: "2022-04-04T22:26:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.51,
                    timestamp: "2022-04-04T22:39:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 49.17,
                    timestamp: "2022-04-04T22:39:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 57,
                    timestamp: "2022-04-04T22:38:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.95,
                    timestamp: "2022-04-04T22:52:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 46.39,
                    timestamp: "2022-04-04T22:49:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-04-04T22:56:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.7,
                    timestamp: "2022-04-04T22:55:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 47.45,
                    timestamp: "2022-04-04T22:54:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 504.04,
                    timestamp: "2022-04-04T23:07:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-04T23:05:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 82.68,
                    timestamp: "2022-04-04T23:09:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 99.25,
                    timestamp: "2022-04-04T23:18:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.36,
                    timestamp: "2022-04-04T23:20:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 45.95,
                    timestamp: "2022-04-04T23:25:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 59,
                    timestamp: "2022-04-04T23:31:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 50.04,
                    timestamp: "2022-04-04T23:41:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 46.49,
                    timestamp: "2022-04-04T23:51:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-04T23:51:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.8,
                    timestamp: "2022-04-04T23:57:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 59,
                    timestamp: "2022-04-05T00:05:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.03,
                    timestamp: "2022-04-05T00:13:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 21.35,
                    timestamp: "2022-04-05T00:18:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 36.95,
                    timestamp: "2022-04-05T00:26:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 40.19,
                    timestamp: "2022-04-05T00:54:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 126.8,
                    timestamp: "2022-04-05T01:07:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 61.91,
                    timestamp: "2022-04-05T01:36:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-05T04:48:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 121.72,
                    timestamp: "2022-04-05T04:28:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 93.11,
                    timestamp: "2022-04-05T04:10:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 61.71,
                    timestamp: "2022-04-05T04:00:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 106.87,
                    timestamp: "2022-04-05T03:44:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 65.14,
                    timestamp: "2022-04-05T03:37:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 89,
                    timestamp: "2022-04-05T02:51:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 48.98,
                    timestamp: "2022-04-05T02:44:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 48.95,
                    timestamp: "2022-04-05T02:43:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 61.14,
                    timestamp: "2022-04-05T02:06:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 45.95,
                    timestamp: "2022-04-05T02:00:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 78,
                    timestamp: "2022-04-05T01:58:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 59.87,
                    timestamp: "2022-04-05T01:55:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.95,
                    timestamp: "2022-04-05T01:51:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 62.71,
                    timestamp: "2022-04-05T07:41:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 49.17,
                    timestamp: "2022-04-05T10:26:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 80,
                    timestamp: "2022-04-05T11:11:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 64,
                    timestamp: "2022-04-05T12:13:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 27.01,
                    timestamp: "2022-04-05T12:25:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 79.5,
                    timestamp: "2022-04-05T12:36:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 57.6,
                    timestamp: "2022-04-05T12:58:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:22:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 141,
                    timestamp: "2022-04-05T13:21:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:30:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:37:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:43:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:43:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:48:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:58:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T14:03:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T14:21:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 59.2,
                    timestamp: "2022-04-05T14:33:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 48.95,
                    timestamp: "2022-04-05T14:43:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-05T14:43:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T15:15:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.57,
                    timestamp: "2022-04-05T15:15:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 56.1,
                    timestamp: "2022-04-05T15:13:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 66.24,
                    timestamp: "2022-04-05T15:28:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.72,
                    timestamp: "2022-04-05T15:52:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 30.69,
                    timestamp: "2022-04-05T16:10:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 36.05,
                    timestamp: "2022-04-05T16:17:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 48.95,
                    timestamp: "2022-04-05T16:26:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-05T16:24:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 49.63,
                    timestamp: "2022-04-05T16:46:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 42.95,
                    timestamp: "2022-04-05T17:08:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 92,
                    timestamp: "2022-04-05T17:24:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 63.64,
                    timestamp: "2022-04-05T17:44:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 46.05,
                    timestamp: "2022-04-05T17:50:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 45.74,
                    timestamp: "2022-04-05T18:04:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.7,
                    timestamp: "2022-04-05T18:02:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 33.41,
                    timestamp: "2022-04-05T18:12:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 68.23,
                    timestamp: "2022-04-05T18:43:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-05T19:01:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 33.95,
                    timestamp: "2022-04-05T18:58:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 47.45,
                    timestamp: "2022-04-05T18:57:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.95,
                    timestamp: "2022-04-05T19:04:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 34.95,
                    timestamp: "2022-04-05T19:40:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 33.27,
                    timestamp: "2022-04-05T19:39:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 43.26,
                    timestamp: "2022-04-05T19:46:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T19:48:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 45.11,
                    timestamp: "2022-04-05T19:54:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 79.4,
                    timestamp: "2022-04-05T20:02:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 33.12,
                    timestamp: "2022-04-05T20:13:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 51.75,
                    timestamp: "2022-04-05T20:51:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 30.95,
                    timestamp: "2022-04-05T21:10:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 51.95,
                    timestamp: "2022-04-05T21:09:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 64.06,
                    timestamp: "2022-04-05T22:03:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 66,
                    timestamp: "2022-04-05T22:16:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 61.62,
                    timestamp: "2022-04-05T22:19:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 58.5,
                    timestamp: "2022-04-05T22:28:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 50.95,
                    timestamp: "2022-04-05T23:11:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-05T23:40:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 108,
                    timestamp: "2022-04-05T23:44:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-05T23:44:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 33.95,
                    timestamp: "2022-04-05T23:49:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 70.29,
                    timestamp: "2022-04-05T23:48:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.95,
                    timestamp: "2022-04-05T23:53:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 33.95,
                    timestamp: "2022-04-06T00:29:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 84.94,
                    timestamp: "2022-04-06T00:36:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 33.95,
                    timestamp: "2022-04-06T00:56:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 74,
                    timestamp: "2022-04-06T01:03:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 46.39,
                    timestamp: "2022-04-06T01:26:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.95,
                    timestamp: "2022-04-06T02:27:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 51.95,
                    timestamp: "2022-04-06T03:23:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 31.27,
                    timestamp: "2022-04-06T03:31:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-06T03:31:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 63.5,
                    timestamp: "2022-04-06T07:45:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-06T09:29:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 103.41,
                    timestamp: "2022-04-06T10:11:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 33.95,
                    timestamp: "2022-04-06T10:38:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 26.45,
                    timestamp: "2022-04-06T11:24:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-06T11:50:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-06T12:11:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 50.58,
                    timestamp: "2022-04-06T13:06:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T14:37:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 51.95,
                    timestamp: "2022-04-06T15:08:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.95,
                    timestamp: "2022-04-06T15:08:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 46.5,
                    timestamp: "2022-04-06T15:19:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 60.3,
                    timestamp: "2022-04-06T15:29:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 54.95,
                    timestamp: "2022-04-06T16:21:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 22.12,
                    timestamp: "2022-04-06T16:26:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 72,
                    timestamp: "2022-04-06T16:25:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.95,
                    timestamp: "2022-04-06T16:31:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 65.48,
                    timestamp: "2022-04-06T16:34:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 59.72,
                    timestamp: "2022-04-06T17:31:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 28.74,
                    timestamp: "2022-04-06T17:37:45.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 51.89,
                    timestamp: "2022-04-06T14:06:38.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 68.28,
                    timestamp: "2022-04-06T14:09:13.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 64,
                    timestamp: "2022-04-06T14:12:47.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 60,
                    timestamp: "2022-04-04T23:41:01.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 71.2,
                    timestamp: "2022-04-05T05:08:20.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 0,
                    timestamp: "2022-04-01T14:52:50.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 11.95,
                    timestamp: "2022-03-31T21:23:13.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 35.15,
                    timestamp: "2022-04-05T06:54:16.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 42.95,
                    timestamp: "2022-04-05T15:05:19.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 79.7,
                    timestamp: "2022-04-05T00:07:19.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 26.52,
                    timestamp: "2022-04-04T21:59:50.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 56.85,
                    timestamp: "2022-04-05T00:51:29.000Z",
                  },
                ],
              },
              {
                id: 16,
                type: "timeline",
                displayType: null,
                displayStyle: "line",
                displayPallete: "Office",
                title: "orders integrated into Sage - tax amount",
                width: 4,
                icon: "shopping-cart",
                aggregationMethod: "sum",
                aggregationInterval: "day",
                groups: [
                  {
                    group: "butterlondon",
                    value: 5.28,
                    timestamp: "2022-03-30T14:20:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T13:50:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T13:46:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.06,
                    timestamp: "2022-03-30T13:36:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T13:24:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T13:03:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.9,
                    timestamp: "2022-03-30T12:23:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.5,
                    timestamp: "2022-03-30T10:45:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.33,
                    timestamp: "2022-03-30T10:15:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T14:40:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T14:59:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0.69,
                    timestamp: "2022-03-30T15:06:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:36:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:35:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.54,
                    timestamp: "2022-03-30T15:28:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:24:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:23:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.5,
                    timestamp: "2022-03-30T15:18:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.04,
                    timestamp: "2022-03-30T15:56:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:54:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T16:35:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T16:41:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.75,
                    timestamp: "2022-03-30T16:55:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T16:59:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.32,
                    timestamp: "2022-03-30T17:11:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.49,
                    timestamp: "2022-03-30T17:23:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.58,
                    timestamp: "2022-03-30T17:25:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.44,
                    timestamp: "2022-03-30T18:18:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.75,
                    timestamp: "2022-03-30T19:33:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.18,
                    timestamp: "2022-03-30T19:44:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T19:59:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T20:22:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.41,
                    timestamp: "2022-03-30T20:42:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.26,
                    timestamp: "2022-03-30T21:35:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T21:40:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T22:23:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T22:44:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.44,
                    timestamp: "2022-03-30T22:56:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:00:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.2,
                    timestamp: "2022-03-30T23:10:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:14:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.4,
                    timestamp: "2022-03-30T23:13:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.36,
                    timestamp: "2022-03-30T23:24:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 18.21,
                    timestamp: "2022-03-30T23:26:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:39:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:58:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.16,
                    timestamp: "2022-03-30T23:57:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.06,
                    timestamp: "2022-03-30T23:52:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.17,
                    timestamp: "2022-03-31T00:11:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T00:34:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T17:39:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.75,
                    timestamp: "2022-03-31T01:55:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.5,
                    timestamp: "2022-03-31T02:14:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T02:20:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.4,
                    timestamp: "2022-03-31T02:56:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T02:53:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T03:10:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T04:02:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T07:35:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T11:42:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.2,
                    timestamp: "2022-03-31T11:49:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T11:55:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T13:30:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-03-31T13:33:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:07:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:16:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:16:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:33:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:38:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:43:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:41:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.4,
                    timestamp: "2022-03-31T14:39:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:53:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:53:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.91,
                    timestamp: "2022-03-31T14:52:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.7,
                    timestamp: "2022-03-31T14:57:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.85,
                    timestamp: "2022-03-31T15:03:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.88,
                    timestamp: "2022-03-31T15:12:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T15:41:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T15:45:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.15,
                    timestamp: "2022-03-31T15:54:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.08,
                    timestamp: "2022-03-31T15:54:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T16:13:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T16:11:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.62,
                    timestamp: "2022-03-31T16:23:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.64,
                    timestamp: "2022-03-31T16:30:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T16:58:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T17:03:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.82,
                    timestamp: "2022-03-31T17:25:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.64,
                    timestamp: "2022-03-31T17:50:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.75,
                    timestamp: "2022-03-31T17:48:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T18:05:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.98,
                    timestamp: "2022-03-31T18:26:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.8,
                    timestamp: "2022-03-31T18:28:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.57,
                    timestamp: "2022-03-31T18:58:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T19:35:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T20:08:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.81,
                    timestamp: "2022-03-31T20:42:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T20:55:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T20:58:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.9,
                    timestamp: "2022-03-31T21:04:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.9,
                    timestamp: "2022-03-31T21:16:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.05,
                    timestamp: "2022-03-31T22:12:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T22:20:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 8.69,
                    timestamp: "2022-03-31T23:39:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T00:21:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T00:47:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T00:50:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.4,
                    timestamp: "2022-04-01T01:32:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.65,
                    timestamp: "2022-04-01T01:39:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.53,
                    timestamp: "2022-04-01T01:49:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9.99,
                    timestamp: "2022-04-01T01:46:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.55,
                    timestamp: "2022-04-01T02:21:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.8,
                    timestamp: "2022-04-01T02:44:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T03:18:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.28,
                    timestamp: "2022-04-01T04:21:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T04:57:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.72,
                    timestamp: "2022-04-01T05:09:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.8,
                    timestamp: "2022-04-01T06:00:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.9,
                    timestamp: "2022-04-01T06:36:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T14:17:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T14:02:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.19,
                    timestamp: "2022-04-01T14:00:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T13:56:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T13:52:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.39,
                    timestamp: "2022-04-01T13:34:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.46,
                    timestamp: "2022-04-01T13:07:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T13:03:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T12:53:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T12:43:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 8.98,
                    timestamp: "2022-04-01T10:42:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T14:36:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T14:41:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.55,
                    timestamp: "2022-04-01T14:50:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T15:02:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.26,
                    timestamp: "2022-04-01T15:00:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.86,
                    timestamp: "2022-04-01T15:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T15:39:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.22,
                    timestamp: "2022-04-01T15:41:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.87,
                    timestamp: "2022-04-01T15:47:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T15:59:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:05:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:26:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:29:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:37:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.06,
                    timestamp: "2022-04-01T16:47:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:50:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.54,
                    timestamp: "2022-04-01T16:48:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T17:35:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.12,
                    timestamp: "2022-04-01T17:34:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.07,
                    timestamp: "2022-04-01T17:34:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.02,
                    timestamp: "2022-04-01T17:50:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T18:04:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.68,
                    timestamp: "2022-04-01T18:11:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T18:27:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T19:01:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.4,
                    timestamp: "2022-04-01T19:07:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.3,
                    timestamp: "2022-04-01T19:17:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.75,
                    timestamp: "2022-04-01T19:21:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.01,
                    timestamp: "2022-04-01T19:23:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.04,
                    timestamp: "2022-04-01T20:26:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T20:24:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T20:23:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.9,
                    timestamp: "2022-04-01T21:07:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T21:05:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T21:10:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T21:09:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.06,
                    timestamp: "2022-04-01T21:14:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.85,
                    timestamp: "2022-04-01T21:27:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T21:47:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T21:50:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.07,
                    timestamp: "2022-04-01T22:11:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 7.29,
                    timestamp: "2022-04-01T23:03:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.51,
                    timestamp: "2022-04-01T23:13:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.33,
                    timestamp: "2022-04-01T23:30:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T23:35:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T23:42:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T23:56:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T00:10:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.61,
                    timestamp: "2022-04-02T00:10:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T00:35:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.01,
                    timestamp: "2022-04-02T00:36:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.04,
                    timestamp: "2022-04-02T00:44:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.75,
                    timestamp: "2022-04-02T01:01:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T01:08:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T01:19:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T01:18:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T01:16:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T02:13:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T03:07:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T03:18:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T03:16:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T03:35:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T03:36:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 14.06,
                    timestamp: "2022-04-02T03:52:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9.05,
                    timestamp: "2022-04-02T04:15:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T06:32:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T07:18:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T11:26:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.04,
                    timestamp: "2022-04-02T11:43:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.15,
                    timestamp: "2022-04-02T12:09:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T12:44:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T13:00:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T13:14:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.48,
                    timestamp: "2022-04-02T13:34:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T13:43:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T13:48:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:00:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 7.34,
                    timestamp: "2022-04-02T14:06:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:15:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:23:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:19:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.26,
                    timestamp: "2022-04-02T14:50:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:47:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.72,
                    timestamp: "2022-04-02T14:55:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0.72,
                    timestamp: "2022-04-02T15:00:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T15:03:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T15:10:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.2,
                    timestamp: "2022-04-02T15:25:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T15:31:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T15:46:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.89,
                    timestamp: "2022-04-02T16:04:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:16:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.2,
                    timestamp: "2022-04-02T16:19:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:25:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.92,
                    timestamp: "2022-04-02T16:27:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:35:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.86,
                    timestamp: "2022-04-02T16:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 7.97,
                    timestamp: "2022-04-02T16:34:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:37:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:52:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.41,
                    timestamp: "2022-04-02T16:59:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:21:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:25:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.3,
                    timestamp: "2022-04-02T17:44:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.09,
                    timestamp: "2022-04-02T17:56:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.93,
                    timestamp: "2022-04-02T17:52:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:52:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.66,
                    timestamp: "2022-04-02T17:59:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 8.65,
                    timestamp: "2022-04-02T18:04:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T18:03:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.09,
                    timestamp: "2022-04-02T18:02:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.78,
                    timestamp: "2022-04-02T18:10:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.93,
                    timestamp: "2022-04-02T18:16:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.65,
                    timestamp: "2022-04-02T18:38:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.21,
                    timestamp: "2022-04-02T19:04:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T19:22:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.43,
                    timestamp: "2022-04-02T19:37:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 14.4,
                    timestamp: "2022-04-02T19:47:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T20:01:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T20:30:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T20:33:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.58,
                    timestamp: "2022-04-02T21:09:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T21:14:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T21:48:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T22:04:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T22:13:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T22:59:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.58,
                    timestamp: "2022-04-02T23:59:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.93,
                    timestamp: "2022-04-03T00:42:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.06,
                    timestamp: "2022-04-03T00:53:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.5,
                    timestamp: "2022-04-03T00:52:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T01:25:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T01:23:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.53,
                    timestamp: "2022-04-03T01:42:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T01:44:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T01:52:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T02:11:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.54,
                    timestamp: "2022-04-03T02:42:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.75,
                    timestamp: "2022-04-03T02:41:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-03T02:46:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.71,
                    timestamp: "2022-04-03T03:39:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9.99,
                    timestamp: "2022-04-03T03:57:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T04:02:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.42,
                    timestamp: "2022-04-03T04:50:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.86,
                    timestamp: "2022-04-03T08:46:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T10:54:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T11:01:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.17,
                    timestamp: "2022-04-03T11:12:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T12:07:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.89,
                    timestamp: "2022-04-03T12:39:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.27,
                    timestamp: "2022-04-03T12:36:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.62,
                    timestamp: "2022-04-03T12:55:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T12:54:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T12:50:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.01,
                    timestamp: "2022-04-03T13:01:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.96,
                    timestamp: "2022-04-03T13:45:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.64,
                    timestamp: "2022-04-03T13:53:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.25,
                    timestamp: "2022-04-03T14:05:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.2,
                    timestamp: "2022-04-03T14:08:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T14:43:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.56,
                    timestamp: "2022-04-03T14:42:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T14:56:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.24,
                    timestamp: "2022-04-03T15:25:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T15:36:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T15:52:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:00:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9.78,
                    timestamp: "2022-04-03T16:40:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:43:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:42:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 7.2,
                    timestamp: "2022-04-03T16:50:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.81,
                    timestamp: "2022-04-03T16:50:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:55:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.34,
                    timestamp: "2022-04-03T17:05:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T17:10:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T17:32:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T17:38:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.66,
                    timestamp: "2022-04-03T17:44:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.4,
                    timestamp: "2022-04-03T18:09:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T18:25:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.96,
                    timestamp: "2022-04-03T18:42:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.04,
                    timestamp: "2022-04-03T18:40:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.94,
                    timestamp: "2022-04-03T19:02:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.65,
                    timestamp: "2022-04-03T19:06:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.63,
                    timestamp: "2022-04-03T19:30:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.53,
                    timestamp: "2022-04-03T19:26:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T19:34:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.12,
                    timestamp: "2022-04-03T19:41:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T20:01:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T20:12:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.12,
                    timestamp: "2022-04-03T20:25:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T20:40:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.5,
                    timestamp: "2022-04-03T20:56:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T21:06:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T21:55:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T22:33:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.75,
                    timestamp: "2022-04-03T22:49:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T22:59:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.29,
                    timestamp: "2022-04-03T23:04:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.13,
                    timestamp: "2022-04-03T23:16:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T23:49:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T23:49:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T00:04:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.58,
                    timestamp: "2022-04-04T00:09:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T01:14:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.85,
                    timestamp: "2022-04-04T01:18:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.5,
                    timestamp: "2022-04-04T01:54:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.17,
                    timestamp: "2022-04-04T02:05:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T03:00:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T03:17:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 10.92,
                    timestamp: "2022-04-04T03:21:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.85,
                    timestamp: "2022-04-04T03:38:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.06,
                    timestamp: "2022-04-04T03:43:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.56,
                    timestamp: "2022-04-04T04:13:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T04:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.61,
                    timestamp: "2022-04-04T06:36:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T11:10:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T12:51:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T14:20:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T14:38:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 8.27,
                    timestamp: "2022-04-04T15:05:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T15:14:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.17,
                    timestamp: "2022-04-04T15:23:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T15:34:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T15:38:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T15:47:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T16:02:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T16:42:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.14,
                    timestamp: "2022-04-04T16:53:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.55,
                    timestamp: "2022-04-04T17:06:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T17:16:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.04,
                    timestamp: "2022-04-04T17:11:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T17:57:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T18:01:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.68,
                    timestamp: "2022-04-04T18:59:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.69,
                    timestamp: "2022-04-04T19:12:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.36,
                    timestamp: "2022-04-04T19:42:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.03,
                    timestamp: "2022-04-04T19:40:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T19:54:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T20:12:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T20:12:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.65,
                    timestamp: "2022-04-04T20:15:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.55,
                    timestamp: "2022-04-04T21:18:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-04T21:47:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:30:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.71,
                    timestamp: "2022-04-04T22:29:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.78,
                    timestamp: "2022-04-04T22:26:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.56,
                    timestamp: "2022-04-04T22:39:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.22,
                    timestamp: "2022-04-04T22:39:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:38:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2,
                    timestamp: "2022-04-04T22:52:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.44,
                    timestamp: "2022-04-04T22:49:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:56:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.75,
                    timestamp: "2022-04-04T22:55:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:54:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 33.04,
                    timestamp: "2022-04-04T23:07:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:05:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.68,
                    timestamp: "2022-04-04T23:09:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9.25,
                    timestamp: "2022-04-04T23:18:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.36,
                    timestamp: "2022-04-04T23:20:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:25:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:31:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.09,
                    timestamp: "2022-04-04T23:41:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.54,
                    timestamp: "2022-04-04T23:51:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:51:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.85,
                    timestamp: "2022-04-04T23:57:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T00:05:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.08,
                    timestamp: "2022-04-05T00:13:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T00:18:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T00:26:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.24,
                    timestamp: "2022-04-05T00:54:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9.8,
                    timestamp: "2022-04-05T01:07:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.96,
                    timestamp: "2022-04-05T01:36:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T04:48:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 11.32,
                    timestamp: "2022-04-05T04:28:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 8.11,
                    timestamp: "2022-04-05T04:10:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.71,
                    timestamp: "2022-04-05T04:00:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.07,
                    timestamp: "2022-04-05T03:44:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.19,
                    timestamp: "2022-04-05T03:37:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T02:51:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.03,
                    timestamp: "2022-04-05T02:44:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T02:43:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.14,
                    timestamp: "2022-04-05T02:06:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T02:00:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T01:58:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.92,
                    timestamp: "2022-04-05T01:55:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T01:51:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.11,
                    timestamp: "2022-04-05T07:41:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.22,
                    timestamp: "2022-04-05T10:26:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T11:11:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4,
                    timestamp: "2022-04-05T12:13:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.06,
                    timestamp: "2022-04-05T12:25:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.5,
                    timestamp: "2022-04-05T12:36:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T12:58:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:22:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:21:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:30:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:37:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:43:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:43:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:48:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:58:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T14:03:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T14:21:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T14:33:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T14:43:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T14:43:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T15:15:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.62,
                    timestamp: "2022-04-05T15:15:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T15:13:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.29,
                    timestamp: "2022-04-05T15:28:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.77,
                    timestamp: "2022-04-05T15:52:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.74,
                    timestamp: "2022-04-05T16:10:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.1,
                    timestamp: "2022-04-05T16:17:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T16:26:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T16:24:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.68,
                    timestamp: "2022-04-05T16:46:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T17:08:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T17:24:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.44,
                    timestamp: "2022-04-05T17:44:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T17:50:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.79,
                    timestamp: "2022-04-05T18:04:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.75,
                    timestamp: "2022-04-05T18:02:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.46,
                    timestamp: "2022-04-05T18:12:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.23,
                    timestamp: "2022-04-05T18:43:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T19:01:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T18:58:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T18:57:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T19:04:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T19:40:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.32,
                    timestamp: "2022-04-05T19:39:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.31,
                    timestamp: "2022-04-05T19:46:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T19:48:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.16,
                    timestamp: "2022-04-05T19:54:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 7.4,
                    timestamp: "2022-04-05T20:02:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.17,
                    timestamp: "2022-04-05T20:13:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T20:51:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T21:10:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T21:09:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.56,
                    timestamp: "2022-04-05T22:03:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T22:16:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.04,
                    timestamp: "2022-04-05T22:19:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T22:28:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:11:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:40:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:44:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:44:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:49:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.29,
                    timestamp: "2022-04-05T23:48:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:53:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T00:29:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.94,
                    timestamp: "2022-04-06T00:36:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T00:56:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T01:03:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.44,
                    timestamp: "2022-04-06T01:26:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T02:27:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T03:23:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 2.32,
                    timestamp: "2022-04-06T03:31:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T03:31:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.9,
                    timestamp: "2022-04-06T07:45:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T09:29:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T10:11:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T10:38:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.5,
                    timestamp: "2022-04-06T11:24:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T11:50:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T12:11:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.13,
                    timestamp: "2022-04-06T13:06:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T14:37:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T15:08:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T15:08:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.55,
                    timestamp: "2022-04-06T15:19:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.1,
                    timestamp: "2022-04-06T15:29:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T16:21:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.67,
                    timestamp: "2022-04-06T16:26:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T16:25:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T16:31:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.53,
                    timestamp: "2022-04-06T16:34:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.72,
                    timestamp: "2022-04-06T17:31:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 1.79,
                    timestamp: "2022-04-06T17:37:45.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 2.94,
                    timestamp: "2022-04-06T14:06:38.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 3.87,
                    timestamp: "2022-04-06T14:09:13.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 3.63,
                    timestamp: "2022-04-06T14:12:47.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 0,
                    timestamp: "2022-04-04T23:41:01.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 0,
                    timestamp: "2022-04-05T05:08:20.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 0,
                    timestamp: "2022-04-01T14:52:50.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 0,
                    timestamp: "2022-03-31T21:23:13.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 0,
                    timestamp: "2022-04-05T06:54:16.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 0,
                    timestamp: "2022-04-05T15:05:19.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 6.1,
                    timestamp: "2022-04-05T00:07:19.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 1.97,
                    timestamp: "2022-04-04T21:59:50.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 0,
                    timestamp: "2022-04-05T00:51:29.000Z",
                  },
                ],
              },
              {
                id: 17,
                type: "timeline",
                displayType: null,
                displayStyle: "line",
                displayPallete: "Office",
                title: "orders integrated into Sage - freight amount",
                width: 4,
                icon: "shopping-cart",
                aggregationMethod: "sum",
                aggregationInterval: "day",
                groups: [
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T14:20:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T13:50:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T13:46:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T13:36:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T13:24:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T13:03:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T12:23:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T10:45:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T10:15:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T14:40:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T14:59:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T15:06:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T15:36:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:35:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24.31,
                    timestamp: "2022-03-30T15:28:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T15:24:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:23:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T15:18:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T15:56:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T15:54:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T16:35:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T16:41:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T16:55:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T16:59:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T17:11:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T17:23:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T17:25:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T18:18:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T19:33:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T19:44:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T19:59:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T20:22:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T20:42:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T21:35:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T21:40:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T22:23:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T22:44:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T22:56:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:00:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:10:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T23:14:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:13:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:24:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:26:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:39:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:58:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T23:57:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-30T23:52:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T00:11:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T00:34:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T17:39:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T01:55:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T02:14:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T02:20:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T02:56:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T02:53:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T03:10:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T04:02:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T07:35:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T11:42:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T11:49:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T11:55:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T13:30:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T13:33:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T14:07:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:16:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T14:16:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T14:33:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:38:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T14:43:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T14:41:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T14:39:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T14:53:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T14:53:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T14:52:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T14:57:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T15:03:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T15:12:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T15:41:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T15:45:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T15:54:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T15:54:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T16:13:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T16:11:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T16:23:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T16:30:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T16:58:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T17:03:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T17:25:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T17:50:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T17:48:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T18:05:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T18:26:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T18:28:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T18:58:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T19:35:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T20:08:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T20:42:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T20:55:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T20:58:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T21:04:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T21:16:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T22:12:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-03-31T22:20:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T23:39:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T00:21:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T00:47:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T00:50:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T01:32:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 36.59,
                    timestamp: "2022-04-01T01:39:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T01:49:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T01:46:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T02:21:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T02:44:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T03:18:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T04:21:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T04:57:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T05:09:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T06:00:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T06:36:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T14:17:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T14:02:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T14:00:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T13:56:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T13:52:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T13:34:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T13:07:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T13:03:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T12:53:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T12:43:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T10:42:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T14:36:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T14:41:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T14:50:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T15:02:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T15:00:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T15:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T15:39:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T15:41:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T15:47:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T15:59:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:05:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T16:26:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T16:29:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T16:37:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T16:47:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T16:50:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T16:48:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T17:35:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T17:34:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T17:34:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T17:50:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T18:04:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T18:11:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T18:27:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T19:01:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T19:07:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T19:17:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T19:21:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T19:23:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T20:26:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T20:24:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T20:23:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T21:07:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T21:05:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T21:10:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T21:09:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T21:14:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T21:27:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T21:47:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T21:50:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T22:11:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T23:03:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T23:13:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T23:30:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T23:35:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T23:42:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-01T23:56:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T00:10:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T00:10:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T00:35:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T00:36:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T00:44:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T01:01:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T01:08:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T01:19:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T01:18:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T01:16:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T02:13:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T03:07:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T03:18:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T03:16:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T03:35:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T03:36:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T03:52:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T04:15:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T06:32:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T07:18:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T11:26:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T11:43:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T12:09:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T12:44:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T13:00:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T13:14:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T13:34:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T13:43:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T13:48:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T14:00:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:06:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T14:15:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T14:23:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:19:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T14:50:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T14:47:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:55:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T15:00:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T15:03:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T15:10:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T15:25:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T15:31:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T15:46:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T16:04:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T16:16:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:19:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:25:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:27:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:35:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T16:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:34:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T16:37:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:52:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:59:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:21:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:25:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T17:44:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:56:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:52:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T17:52:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:59:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T18:04:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T18:03:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T18:02:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T18:10:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T18:16:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T18:38:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T19:04:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T19:22:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T19:37:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T19:47:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T20:01:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T20:30:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T20:33:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T21:09:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T21:14:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T21:48:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T22:04:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T22:13:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T22:59:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-02T23:59:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T00:42:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T00:53:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T00:52:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T01:25:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T01:23:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T01:42:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T01:44:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T01:52:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T02:11:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T02:42:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T02:41:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T02:46:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T03:39:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T03:57:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T04:02:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T04:50:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T08:46:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T10:54:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T11:01:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T11:12:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T12:07:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T12:39:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T12:36:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T12:55:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T12:54:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T12:50:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T13:01:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T13:45:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T13:53:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T14:05:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T14:08:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T14:43:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T14:42:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T14:56:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T15:25:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T15:36:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T15:52:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:00:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:40:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T16:43:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:42:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:50:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:50:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T16:55:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T17:05:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T17:10:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T17:32:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T17:38:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T17:44:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T18:09:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T18:25:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T18:42:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T18:40:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T19:02:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T19:06:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T19:30:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T19:26:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T19:34:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T19:41:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T20:01:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T20:12:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T20:25:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T20:40:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T20:56:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T21:06:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T21:55:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T22:33:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T22:49:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T22:59:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T23:04:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T23:16:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T23:49:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-03T23:49:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T00:04:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T00:09:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T01:14:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T01:18:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T01:54:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T02:05:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T03:00:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T03:17:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T03:21:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T03:38:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T03:43:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T04:13:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T04:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T06:36:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T11:10:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T12:51:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T14:20:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T14:38:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T15:05:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T15:14:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T15:23:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T15:34:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T15:38:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T15:47:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T16:02:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T16:42:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T16:53:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T17:06:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T17:16:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T17:11:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 25.41,
                    timestamp: "2022-04-04T17:57:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T18:01:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T18:59:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T19:12:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T19:42:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T19:40:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T19:54:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T20:12:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T20:12:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T20:15:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T21:18:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T21:47:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:30:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T22:29:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:26:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T22:39:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T22:39:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:38:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T22:52:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T22:49:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T22:56:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T22:55:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T22:54:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:07:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T23:05:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:09:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:18:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:20:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T23:25:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:31:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T23:41:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T23:51:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T23:51:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-04T23:57:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T00:05:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T00:13:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T00:18:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T00:26:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T00:54:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T01:07:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T01:36:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T04:48:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T04:28:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T04:10:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T04:00:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T03:44:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T03:37:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T02:51:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T02:44:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T02:43:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T02:06:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T02:00:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T01:58:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T01:55:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T01:51:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T07:41:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T10:26:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T11:11:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T12:13:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T12:25:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T12:36:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T12:58:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:22:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:21:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:30:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:37:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:43:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:43:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:48:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:58:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T14:03:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T14:21:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T14:33:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T14:43:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T14:43:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T15:15:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T15:15:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T15:13:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T15:28:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T15:52:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T16:10:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T16:17:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T16:26:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T16:24:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T16:46:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T17:08:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T17:24:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T17:44:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T17:50:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T18:04:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T18:02:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T18:12:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T18:43:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T19:01:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T18:58:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T18:57:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T19:04:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T19:40:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T19:39:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T19:46:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T19:48:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T19:54:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T20:02:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T20:13:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T20:51:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T21:10:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T21:09:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T22:03:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T22:16:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T22:19:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T22:28:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T23:11:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T23:40:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:44:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T23:44:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T23:49:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:48:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-05T23:53:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T00:29:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T00:36:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T00:56:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T01:03:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T01:26:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T02:27:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T03:23:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T03:31:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T03:31:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T07:45:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T09:29:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 25.41,
                    timestamp: "2022-04-06T10:11:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T10:38:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T11:24:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T11:50:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T12:11:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T13:06:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T14:37:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T15:08:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T15:08:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T15:19:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T15:29:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T16:21:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T16:26:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T16:25:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T16:31:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T16:34:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T17:31:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.95,
                    timestamp: "2022-04-06T17:37:45.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 6.95,
                    timestamp: "2022-04-06T14:06:38.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 25.41,
                    timestamp: "2022-04-06T14:09:13.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 29.97,
                    timestamp: "2022-04-06T14:12:47.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 0,
                    timestamp: "2022-04-04T23:41:01.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 0,
                    timestamp: "2022-04-05T05:08:20.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 0,
                    timestamp: "2022-04-01T14:52:50.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 7.95,
                    timestamp: "2022-03-31T21:23:13.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 7.95,
                    timestamp: "2022-04-05T06:54:16.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 6.95,
                    timestamp: "2022-04-05T15:05:19.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 0,
                    timestamp: "2022-04-05T00:07:19.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 6.95,
                    timestamp: "2022-04-04T21:59:50.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 12.6,
                    timestamp: "2022-04-05T00:51:29.000Z",
                  },
                ],
              },
              {
                id: 18,
                type: "timeline",
                displayType: null,
                displayStyle: "line",
                displayPallete: "Office",
                title: "orders integrated into Sage - discount amount",
                width: 4,
                icon: "shopping-cart",
                aggregationMethod: "sum",
                aggregationInterval: "day",
                groups: [
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T14:20:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T13:50:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T13:46:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T13:36:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 18.25,
                    timestamp: "2022-03-30T13:24:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T13:03:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.6,
                    timestamp: "2022-03-30T12:23:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T10:45:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T10:15:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 20.5,
                    timestamp: "2022-03-30T14:40:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 14.4,
                    timestamp: "2022-03-30T14:59:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 13.5,
                    timestamp: "2022-03-30T15:06:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 13.4,
                    timestamp: "2022-03-30T15:36:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:35:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:28:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:24:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:23:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:18:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-03-30T15:56:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:54:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T16:35:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T16:41:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T16:55:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T16:59:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T17:11:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 22.62,
                    timestamp: "2022-03-30T17:23:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T17:25:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T18:18:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T19:33:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T19:44:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 18.6,
                    timestamp: "2022-03-30T19:59:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T20:22:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T20:42:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T21:35:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T21:40:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T22:23:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T22:44:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T22:56:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 14.4,
                    timestamp: "2022-03-30T23:00:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:10:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:14:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 18,
                    timestamp: "2022-03-30T23:13:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 18.4,
                    timestamp: "2022-03-30T23:24:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:26:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:39:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 28.8,
                    timestamp: "2022-03-30T23:58:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:57:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:52:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T00:11:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T00:34:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T17:39:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T01:55:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 14.4,
                    timestamp: "2022-03-31T02:14:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T02:20:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T02:56:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T02:53:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T03:10:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T04:02:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T07:35:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 11.7,
                    timestamp: "2022-03-31T11:42:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T11:49:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T11:55:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T13:30:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T13:33:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:07:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:16:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 12,
                    timestamp: "2022-03-31T14:16:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 18,
                    timestamp: "2022-03-31T14:33:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 16.8,
                    timestamp: "2022-03-31T14:38:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:43:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:41:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:39:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:53:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:53:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 13.2,
                    timestamp: "2022-03-31T14:52:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-03-31T14:57:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T15:03:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T15:12:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 78,
                    timestamp: "2022-03-31T15:41:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T15:45:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T15:54:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 7.6,
                    timestamp: "2022-03-31T15:54:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T16:13:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 12.6,
                    timestamp: "2022-03-31T16:11:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T16:23:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.5,
                    timestamp: "2022-03-31T16:30:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T16:58:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 7.5,
                    timestamp: "2022-03-31T17:03:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T17:25:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T17:50:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T17:48:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T18:05:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T18:26:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 14.4,
                    timestamp: "2022-03-31T18:28:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 14.8,
                    timestamp: "2022-03-31T18:58:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T19:35:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T20:08:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 18.5,
                    timestamp: "2022-03-31T20:42:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 46.5,
                    timestamp: "2022-03-31T20:55:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T20:58:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 15,
                    timestamp: "2022-03-31T21:04:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T21:16:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T22:12:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T22:20:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 23.8,
                    timestamp: "2022-03-31T23:39:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T00:21:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T00:47:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T00:50:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T01:32:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T01:39:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T01:49:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T01:46:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T02:21:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T02:44:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T03:18:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T04:21:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T04:57:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T05:09:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T06:00:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 14.4,
                    timestamp: "2022-04-01T06:36:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T14:17:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 13.2,
                    timestamp: "2022-04-01T14:02:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T14:00:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 16.8,
                    timestamp: "2022-04-01T13:56:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 14.4,
                    timestamp: "2022-04-01T13:52:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-04-01T13:34:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T13:07:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T13:03:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T12:53:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T12:43:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T10:42:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T14:36:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T14:41:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T14:50:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T15:02:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T15:00:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T15:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 22,
                    timestamp: "2022-04-01T15:39:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T15:41:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T15:47:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 13.5,
                    timestamp: "2022-04-01T15:59:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:05:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:26:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:29:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:37:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:47:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:50:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:48:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T17:35:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T17:34:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T17:34:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 15.2,
                    timestamp: "2022-04-01T17:50:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T18:04:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T18:11:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T18:27:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 12,
                    timestamp: "2022-04-01T19:01:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T19:07:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T19:17:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T19:21:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 11.6,
                    timestamp: "2022-04-01T19:23:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T20:26:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T20:24:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-04-01T20:23:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.4,
                    timestamp: "2022-04-01T21:07:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 18.75,
                    timestamp: "2022-04-01T21:05:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T21:10:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 16.5,
                    timestamp: "2022-04-01T21:09:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T21:14:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 15,
                    timestamp: "2022-04-01T21:27:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 20.5,
                    timestamp: "2022-04-01T21:47:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T21:50:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T22:11:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-04-01T23:03:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T23:13:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 18.5,
                    timestamp: "2022-04-01T23:30:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T23:35:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 5.85,
                    timestamp: "2022-04-01T23:42:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T23:56:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T00:10:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T00:10:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 24,
                    timestamp: "2022-04-02T00:35:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T00:36:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T00:44:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T01:01:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T01:08:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T01:19:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T01:18:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T01:16:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T02:13:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T03:07:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T03:18:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 13.5,
                    timestamp: "2022-04-02T03:16:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T03:35:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6,
                    timestamp: "2022-04-02T03:36:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T03:52:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T04:15:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T06:32:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T07:18:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T11:26:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T11:43:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 15.6,
                    timestamp: "2022-04-02T12:09:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T12:44:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T13:00:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T13:14:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T13:34:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T13:43:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T13:48:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:00:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 22.2,
                    timestamp: "2022-04-02T14:06:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:15:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:23:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 23.25,
                    timestamp: "2022-04-02T14:19:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:50:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:47:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:55:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T15:00:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T15:03:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T15:10:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T15:25:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T15:31:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 28.8,
                    timestamp: "2022-04-02T15:46:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:04:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:16:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 19.5,
                    timestamp: "2022-04-02T16:19:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 15,
                    timestamp: "2022-04-02T16:25:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 14,
                    timestamp: "2022-04-02T16:27:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:35:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:34:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:37:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:52:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:59:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:21:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:25:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:44:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 25.2,
                    timestamp: "2022-04-02T17:56:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:52:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:52:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:59:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T18:04:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T18:03:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T18:02:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 10,
                    timestamp: "2022-04-02T18:10:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T18:16:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T18:38:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 17.7,
                    timestamp: "2022-04-02T19:04:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T19:22:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T19:37:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T19:47:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T20:01:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T20:30:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T20:33:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T21:09:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T21:14:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T21:48:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T22:04:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T22:13:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T22:59:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T23:59:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 16.5,
                    timestamp: "2022-04-03T00:42:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T00:53:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T00:52:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T01:25:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 18,
                    timestamp: "2022-04-03T01:23:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T01:42:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T01:44:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T01:52:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T02:11:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-04-03T02:42:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T02:41:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T02:46:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9.9,
                    timestamp: "2022-04-03T03:39:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 32.4,
                    timestamp: "2022-04-03T03:57:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T04:02:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.75,
                    timestamp: "2022-04-03T04:50:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T08:46:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T10:54:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T11:01:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T11:12:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T12:07:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 12,
                    timestamp: "2022-04-03T12:39:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T12:36:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T12:55:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T12:54:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T12:50:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T13:01:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T13:45:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T13:53:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 17.6,
                    timestamp: "2022-04-03T14:05:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T14:08:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T14:43:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T14:42:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 18.5,
                    timestamp: "2022-04-03T14:56:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T15:25:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 10,
                    timestamp: "2022-04-03T15:36:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T15:52:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:00:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:40:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:43:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:42:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:50:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 14.6,
                    timestamp: "2022-04-03T16:50:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 13.2,
                    timestamp: "2022-04-03T16:55:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T17:05:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T17:10:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T17:32:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 58.25,
                    timestamp: "2022-04-03T17:38:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T17:44:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T18:09:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 14.4,
                    timestamp: "2022-04-03T18:25:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T18:42:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T18:40:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T19:02:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T19:06:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T19:30:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 12,
                    timestamp: "2022-04-03T19:26:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 14.4,
                    timestamp: "2022-04-03T19:34:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T19:41:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-04-03T20:01:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T20:12:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T20:25:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T20:40:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T20:56:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T21:06:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T21:55:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 14.6,
                    timestamp: "2022-04-03T22:33:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T22:49:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 21,
                    timestamp: "2022-04-03T22:59:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T23:04:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T23:16:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T23:49:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T23:49:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T00:04:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 16,
                    timestamp: "2022-04-04T00:09:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-04-04T01:14:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T01:18:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T01:54:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T02:05:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T03:00:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-04-04T03:17:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T03:21:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T03:38:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T03:43:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 19,
                    timestamp: "2022-04-04T04:13:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T04:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 12,
                    timestamp: "2022-04-04T06:36:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T11:10:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T12:51:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T14:20:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 12,
                    timestamp: "2022-04-04T14:38:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T15:05:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T15:14:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T15:23:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T15:34:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-04-04T15:38:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 7.2,
                    timestamp: "2022-04-04T15:47:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T16:02:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T16:42:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T16:53:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T17:06:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T17:16:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T17:11:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T17:57:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T18:01:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T18:59:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T19:12:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T19:42:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T19:40:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T19:54:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 21,
                    timestamp: "2022-04-04T20:12:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T20:12:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T20:15:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 8,
                    timestamp: "2022-04-04T21:18:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T21:47:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:30:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:29:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:26:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:39:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:39:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:38:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:52:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:49:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:56:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:55:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 13.5,
                    timestamp: "2022-04-04T22:54:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 157,
                    timestamp: "2022-04-04T23:07:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:05:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:09:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:18:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:20:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:25:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:31:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:41:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:51:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:51:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:57:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T00:05:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T00:13:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 3.6,
                    timestamp: "2022-04-05T00:18:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T00:26:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T00:54:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T01:07:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T01:36:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T04:48:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 27.6,
                    timestamp: "2022-04-05T04:28:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T04:10:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T04:00:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 25.2,
                    timestamp: "2022-04-05T03:44:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T03:37:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T02:51:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T02:44:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T02:43:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T02:06:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T02:00:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T01:58:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T01:55:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T01:51:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 14.4,
                    timestamp: "2022-04-05T07:41:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T10:26:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 20,
                    timestamp: "2022-04-05T11:11:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T12:13:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T12:25:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T12:36:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 14.4,
                    timestamp: "2022-04-05T12:58:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 40,
                    timestamp: "2022-04-05T13:22:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:21:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 40,
                    timestamp: "2022-04-05T13:30:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 25,
                    timestamp: "2022-04-05T13:37:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 18,
                    timestamp: "2022-04-05T13:43:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 40,
                    timestamp: "2022-04-05T13:43:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 40,
                    timestamp: "2022-04-05T13:48:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 20,
                    timestamp: "2022-04-05T13:58:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 25,
                    timestamp: "2022-04-05T14:03:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 25,
                    timestamp: "2022-04-05T14:21:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 14.8,
                    timestamp: "2022-04-05T14:33:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T14:43:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T14:43:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 74,
                    timestamp: "2022-04-05T15:15:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T15:15:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9.9,
                    timestamp: "2022-04-05T15:13:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T15:28:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T15:52:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T16:10:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-04-05T16:17:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T16:26:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T16:24:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T16:46:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 12,
                    timestamp: "2022-04-05T17:08:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 18,
                    timestamp: "2022-04-05T17:24:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 14.8,
                    timestamp: "2022-04-05T17:44:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 6.9,
                    timestamp: "2022-04-05T17:50:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T18:04:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T18:02:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T18:12:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 21,
                    timestamp: "2022-04-05T18:43:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T19:01:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T18:58:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 13.5,
                    timestamp: "2022-04-05T18:57:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 18,
                    timestamp: "2022-04-05T19:04:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T19:40:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T19:39:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T19:46:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 25,
                    timestamp: "2022-04-05T19:48:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T19:54:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T20:02:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T20:13:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 11.2,
                    timestamp: "2022-04-05T20:51:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T21:10:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 15,
                    timestamp: "2022-04-05T21:09:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 19.5,
                    timestamp: "2022-04-05T22:03:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T22:16:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 16.87,
                    timestamp: "2022-04-05T22:19:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T22:28:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:11:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:40:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:44:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:44:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-04-05T23:49:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:48:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:53:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-04-06T00:29:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T00:36:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-04-06T00:56:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T01:03:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T01:26:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T02:27:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 15,
                    timestamp: "2022-04-06T03:23:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T03:31:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T03:31:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 14.4,
                    timestamp: "2022-04-06T07:45:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T09:29:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T10:11:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-04-06T10:38:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T11:24:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T11:50:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T12:11:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 13.5,
                    timestamp: "2022-04-06T13:06:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 22,
                    timestamp: "2022-04-06T14:37:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 9,
                    timestamp: "2022-04-06T15:08:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T15:08:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T15:19:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 13.8,
                    timestamp: "2022-04-06T15:29:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T16:21:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 4.5,
                    timestamp: "2022-04-06T16:26:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 18,
                    timestamp: "2022-04-06T16:25:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T16:31:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T16:34:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T17:31:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T17:37:45.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 0,
                    timestamp: "2022-04-06T14:06:38.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 0,
                    timestamp: "2022-04-06T14:09:13.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 7.6,
                    timestamp: "2022-04-06T14:12:47.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 15,
                    timestamp: "2022-04-04T23:41:01.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 17.8,
                    timestamp: "2022-04-05T05:08:20.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 30,
                    timestamp: "2022-04-01T14:52:50.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 0,
                    timestamp: "2022-03-31T21:23:13.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 4.8,
                    timestamp: "2022-04-05T06:54:16.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 0,
                    timestamp: "2022-04-05T15:05:19.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 18.4,
                    timestamp: "2022-04-05T00:07:19.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 4.4,
                    timestamp: "2022-04-04T21:59:50.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 14.75,
                    timestamp: "2022-04-05T00:51:29.000Z",
                  },
                ],
              },
              {
                id: 19,
                type: "timeline",
                displayType: null,
                displayStyle: "line",
                displayPallete: "Office",
                title: "orders integrated into Sage - gift card amount",
                width: 4,
                icon: "shopping-cart",
                aggregationMethod: "sum",
                aggregationInterval: "day",
                groups: [
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T14:20:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T13:50:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T13:46:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T13:36:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T13:24:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T13:03:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T12:23:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T10:45:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T10:15:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T14:40:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T14:59:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:06:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:36:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:35:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:28:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:24:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:23:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:18:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:56:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T15:54:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T16:35:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T16:41:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T16:55:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T16:59:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T17:11:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T17:23:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T17:25:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T18:18:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T19:33:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T19:44:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T19:59:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T20:22:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T20:42:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T21:35:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T21:40:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T22:23:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T22:44:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T22:56:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:00:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:10:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:14:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:13:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:24:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:26:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:39:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:58:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:57:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T23:52:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T00:11:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T00:34:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-30T17:39:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T01:55:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T02:14:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T02:20:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T02:56:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T02:53:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T03:10:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T04:02:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T07:35:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T11:42:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T11:49:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T11:55:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T13:30:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T13:33:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:07:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:16:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:16:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:33:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:38:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:43:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:41:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:39:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:53:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:53:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:52:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T14:57:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T15:03:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T15:12:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T15:41:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T15:45:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T15:54:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T15:54:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T16:13:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T16:11:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T16:23:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T16:30:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T16:58:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T17:03:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T17:25:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T17:50:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T17:48:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T18:05:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T18:26:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T18:28:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T18:58:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T19:35:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T20:08:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T20:42:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T20:55:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T20:58:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T21:04:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T21:16:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T22:12:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T22:20:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-03-31T23:39:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T00:21:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T00:47:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T00:50:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T01:32:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T01:39:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T01:49:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T01:46:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T02:21:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T02:44:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T03:18:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T04:21:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T04:57:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T05:09:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T06:00:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T06:36:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T14:17:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T14:02:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T14:00:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T13:56:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T13:52:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T13:34:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T13:07:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T13:03:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T12:53:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T12:43:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T10:42:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T14:36:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T14:41:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T14:50:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T15:02:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T15:00:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T15:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T15:39:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T15:41:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T15:47:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T15:59:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:05:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:26:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:29:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:37:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:47:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:50:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T16:48:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T17:35:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T17:34:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T17:34:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T17:50:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T18:04:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T18:11:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T18:27:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T19:01:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T19:07:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T19:17:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T19:21:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T19:23:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T20:26:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T20:24:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T20:23:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T21:07:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T21:05:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T21:10:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T21:09:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T21:14:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T21:27:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T21:47:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T21:50:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T22:11:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T23:03:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T23:13:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T23:30:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T23:35:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T23:42:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-01T23:56:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T00:10:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T00:10:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T00:35:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T00:36:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T00:44:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T01:01:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T01:08:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T01:19:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T01:18:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T01:16:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T02:13:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T03:07:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T03:18:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T03:16:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T03:35:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T03:36:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T03:52:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T04:15:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T06:32:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T07:18:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T11:26:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T11:43:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T12:09:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T12:44:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T13:00:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T13:14:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T13:34:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T13:43:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T13:48:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:00:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:06:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:15:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:23:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:19:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:50:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:47:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T14:55:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T15:00:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T15:03:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T15:10:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T15:25:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T15:31:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T15:46:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:04:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:16:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:19:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:25:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:27:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:35:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:34:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:37:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:52:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T16:59:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:21:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:25:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:44:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:56:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:52:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:52:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T17:59:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T18:04:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T18:03:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T18:02:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T18:10:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T18:16:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T18:38:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T19:04:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T19:22:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T19:37:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T19:47:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T20:01:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T20:30:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T20:33:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T21:09:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T21:14:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T21:48:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T22:04:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T22:13:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T22:59:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-02T23:59:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T00:42:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T00:53:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T00:52:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T01:25:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T01:23:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T01:42:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T01:44:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T01:52:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T02:11:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T02:42:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T02:41:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T02:46:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T03:39:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T03:57:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T04:02:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T04:50:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T08:46:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T10:54:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T11:01:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T11:12:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T12:07:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T12:39:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T12:36:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T12:55:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T12:54:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T12:50:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T13:01:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T13:45:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T13:53:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T14:05:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T14:08:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T14:43:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T14:42:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T14:56:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T15:25:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T15:36:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T15:52:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:00:03.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:40:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:43:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:42:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:50:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:50:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T16:55:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T17:05:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T17:10:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T17:32:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T17:38:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T17:44:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T18:09:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T18:25:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T18:42:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T18:40:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T19:02:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T19:06:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T19:30:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T19:26:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T19:34:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T19:41:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T20:01:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T20:12:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T20:25:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T20:40:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T20:56:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T21:06:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T21:55:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T22:33:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T22:49:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T22:59:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T23:04:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T23:16:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T23:49:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-03T23:49:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T00:04:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T00:09:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T01:14:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T01:18:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T01:54:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T02:05:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T03:00:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T03:17:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T03:21:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T03:38:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T03:43:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T04:13:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T04:35:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T06:36:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T11:10:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T12:51:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T14:20:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T14:38:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T15:05:51.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T15:14:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T15:23:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T15:34:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T15:38:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T15:47:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T16:02:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T16:42:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T16:53:36.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T17:06:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T17:16:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T17:11:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T17:57:08.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T18:01:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T18:59:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T19:12:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T19:42:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T19:40:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T19:54:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T20:12:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T20:12:11.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T20:15:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T21:18:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T21:47:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:30:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:29:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:26:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:39:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:39:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:38:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:52:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:49:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:56:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:55:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T22:54:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:07:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:05:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:09:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:18:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:20:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:25:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:31:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:41:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:51:53.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:51:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-04T23:57:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T00:05:20.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T00:13:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T00:18:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T00:26:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T00:54:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T01:07:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T01:36:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T04:48:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T04:28:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T04:10:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T04:00:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T03:44:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T03:37:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T02:51:26.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T02:44:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T02:43:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T02:06:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T02:00:00.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T01:58:38.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T01:55:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T01:51:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T07:41:21.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T10:26:49.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T11:11:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T12:13:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T12:25:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T12:36:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T12:58:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:22:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:21:30.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:30:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:37:50.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:43:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:43:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:48:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T13:58:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T14:03:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T14:21:09.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T14:33:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T14:43:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T14:43:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T15:15:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T15:15:23.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T15:13:48.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T15:28:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T15:52:06.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T16:10:10.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T16:17:52.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T16:26:13.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T16:24:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T16:46:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T17:08:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T17:24:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T17:44:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T17:50:55.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T18:04:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T18:02:39.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T18:12:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T18:43:54.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T19:01:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T18:58:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T18:57:43.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T19:04:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T19:40:41.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T19:39:04.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T19:46:22.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T19:48:35.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T19:54:28.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T20:02:14.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T20:13:44.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T20:51:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T21:10:34.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T21:09:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T22:03:32.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T22:16:58.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T22:19:07.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T22:28:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:11:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:40:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:44:56.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:44:47.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:49:02.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:48:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-05T23:53:45.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T00:29:31.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T00:36:19.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T00:56:15.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T01:03:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T01:26:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T02:27:59.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T03:23:46.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T03:31:37.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T03:31:05.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T07:45:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T09:29:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T10:11:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T10:38:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T11:24:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T11:50:57.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T12:11:25.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T13:06:29.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T14:37:40.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T15:08:42.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T15:08:18.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T15:19:16.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T15:29:33.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T16:21:17.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T16:26:12.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T16:25:01.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T16:31:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T16:34:24.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T17:31:27.000Z",
                  },
                  {
                    group: "butterlondon",
                    value: 0,
                    timestamp: "2022-04-06T17:37:45.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 0,
                    timestamp: "2022-04-06T14:06:38.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 0,
                    timestamp: "2022-04-06T14:09:13.000Z",
                  },
                  {
                    group: "cosmedix",
                    value: 0,
                    timestamp: "2022-04-06T14:12:47.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 0,
                    timestamp: "2022-04-04T23:41:01.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 0,
                    timestamp: "2022-04-05T05:08:20.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 0,
                    timestamp: "2022-04-01T14:52:50.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 0,
                    timestamp: "2022-03-31T21:23:13.000Z",
                  },
                  {
                    group: "lysbty",
                    value: 0,
                    timestamp: "2022-04-05T06:54:16.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 0,
                    timestamp: "2022-04-05T15:05:19.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 0,
                    timestamp: "2022-04-05T00:07:19.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 0,
                    timestamp: "2022-04-04T21:59:50.000Z",
                  },
                  {
                    group: "purcosmetics",
                    value: 0,
                    timestamp: "2022-04-05T00:51:29.000Z",
                  },
                ],
              },
              {
                id: 4,
                type: "scalar",
                displayType: "pie",
                displayStyle: "donut",
                displayPallete: "Office",
                title: "failures",
                width: 4,
                icon: "exclamation-circle",
                groups: ["shopify inventory", "shopify order"],
                subGroups: [""],
                data: [
                  {
                    group: "shopify inventory",
                    subGroup: "",
                    value: 34,
                    plantType: "shopify_inventory",
                  },
                  {
                    group: "shopify order",
                    subGroup: "",
                    value: 12,
                    plantType: "shopify_order",
                  },
                ],
              },
              {
                id: 22,
                type: "scalar",
                displayType: "pie",
                displayStyle: "donut",
                displayPallete: "Office",
                title: "frozens",
                width: 4,
                icon: "exclamation-circle",
                groups: ["shopify inventory", "shopify order"],
                subGroups: [""],
                data: [
                  {
                    group: "shopify inventory",
                    subGroup: "",
                    value: 3,
                    plantType: "shopify_inventory",
                  },
                ],
              },
              {
                id: 14,
                type: "scalar",
                displayType: "table",
                displayStyle: "donut",
                displayPallete: "Office",
                title: "in queue",
                width: 4,
                icon: "seedling",
                groups: ["shopify inventory", "shopify order"],
                subGroups: [
                  "butterlondon",
                  "cosmedix",
                  "lysbty",
                  "purcosmetics",
                ],
                data: [
                  {
                    group: "shopify inventory",
                    subGroup: "butterlondon",
                    value: 199,
                    plantType: "shopify_inventory",
                  },
                ],
              },
            ],
          }
        },
        { timing: 500 }
      )
      this.get(
        "http://localhost:1080/api/v1/plants/:id",
        (schema, request) => {
          return {
            nodes: [
              {
                id: 0,
                title: "Launch",
                state: "grown",
                group: 0,
                logs: null,
              },
              {
                id: 1,
                title: "ShopifyOrder",
                state: "grown",
                group: 1,
                logs: null,
              },
              {
                id: 2,
                title: "SageOrder",
                state: "grown",
                group: 2,
                logs: null,
              },
              {
                id: 3,
                title: "SageOrderPayment",
                state: "grown",
                group: 3,
                logs: null,
              },
              {
                id: 4,
                title: "SageOrderDelivery",
                state: "growing",
                group: 3,
                logs: null,
              },
            ],
            links: [
              {
                source: 0,
                target: 1,
              },
              {
                source: 1,
                target: 2,
              },
              {
                source: 2,
                target: 3,
              },
              {
                source: 2,
                target: 4,
              },
            ],
            traits: {
              id: 6,
              shopify_store_name: "butterlondon",
              shopify_order_id: "46710386343",
              sage_order_reference: "410000077602",
              sage_order_date: "2022-03-31T02:20:34.000Z",
              shopify_email: "test@test.com",
              shopify_first_name: "test",
              shopify_last_name: " test",
              sage_sales_order_number: "4671038685343",
              sage_customer_number: "69999999",
              sage_tracking_numbers: "",
              sales_order_integrated: "yes",
              sales_order_payment_integrated: "yes",
              credit_memo_integrated: "n/a",
            },
          }
        },
        { timing: 500 }
      )
      this.get(
        "http://localhost:1080/api/v1/plants/:id/genomes",
        (schema, request) => {
          return {
            mutated: true,
            genomes: [
              {
                genome: {
                  shopify_store_name: "butterlondon",
                  shopify_order_id: 467129126108,
                  shopify_order_price: 86.02,
                  sage_sales_site: "BUTCO",
                  sage_sales_order_no: "467129126108",
                  sage_order_reference: "41000007760",
                  sage_order_date: "2022-02-21T13:55:58-08:00",
                  sage_required_delivery_date: "2022-02-22T13:55:58-08:00",
                  sage_customer_number: "6999999",
                  sage_payment_method: "SHOPIFYC",
                  sage_delivery_method: "USPS",
                  sage_carrier: "USPS",
                  billing_address: {
                    FirstName: "Amy",
                    LastName: "Lemo",
                    Company: "",
                    AddressLine1: "617 West 5th Street",
                    AddressLine2: "",
                    AddressLine3: "",
                    City: "New York",
                    StateCode: "NY",
                    ZipCode: "10021",
                    CountryCode: "US",
                    Phone: null,
                  },
                  delivery_address: {
                    FirstName: "Amy",
                    LastName: "Lemo",
                    Company: "",
                    AddressLine1: "617 West 5th Street",
                    AddressLine2: "",
                    AddressLine3: "",
                    City: "New York",
                    StateCode: "NY",
                    ZipCode: "10021",
                    CountryCode: "US",
                    Phone: null,
                  },
                  sage_freight_amount: 0,
                  sage_discout_amount: 0,
                  sage_sales_tax_amount: 7.02,
                  sage_gift_card_amount: 20.65,
                  sage_line_items: [
                    {
                      Sku: "",
                      Description: "butter LONDON Ultimate Manicure Kit",
                      Quantity: 1,
                      Price: 79,
                    },
                  ],
                  sage_payment_bank: "1066",
                  sage_payment_entry_reference: "",
                  sage_payment_amount: 65.37,
                  sage_payment_date: "2022-02-21T13:55:52-08:00",
                  sage_payment_account: "11000",
                  sage_payment_currency: "USD",
                },
                note: null,
                user: null,
                timestamp: null,
              },
              {
                genome: {
                  shopify_store_name: "butterlondon 22",
                  shopify_order_id: 467129126108,
                  shopify_order_price: 86.02,
                  sage_sales_site: "BUTCO",
                  sage_sales_order_no: "467129126108",
                  sage_order_reference: "41000007760",
                  sage_order_date: "2022-02-21T13:55:58-08:00",
                  sage_required_delivery_date: "2022-02-22T13:55:58-08:00",
                  sage_customer_number: "6999999",
                  sage_payment_method: "SHOPIFYC",
                  sage_delivery_method: "USPS",
                  sage_carrier: "USPS",
                  billing_address: {
                    FirstName: "Amy",
                    LastName: "Lemo",
                    Company: "",
                    AddressLine1: "617 West 5th Street",
                    AddressLine2: "",
                    AddressLine3: "",
                    City: "New York",
                    StateCode: "NY",
                    ZipCode: "10021",
                    CountryCode: "US",
                    Phone: null,
                  },
                  delivery_address: {
                    FirstName: "Amy",
                    LastName: "Lemo",
                    Company: "",
                    AddressLine1: "617 West 5th Street",
                    AddressLine2: "",
                    AddressLine3: "",
                    City: "New York",
                    StateCode: "NY",
                    ZipCode: "10021",
                    CountryCode: "US",
                    Phone: null,
                  },
                  sage_freight_amount: 0,
                  sage_discout_amount: 0,
                  sage_sales_tax_amount: 7.02,
                  sage_gift_card_amount: 20.65,
                  sage_line_items: [
                    {
                      Sku: "",
                      Description: "butter LONDON Ultimate Manicure Kit",
                      Quantity: 1,
                      Price: 79,
                    },
                  ],
                  sage_payment_bank: "1066",
                  sage_payment_entry_reference: "",
                  sage_payment_amount: 65.37,
                  sage_payment_date: "2022-02-21T13:55:52-08:00",
                  sage_payment_account: "11000",
                  sage_payment_currency: "USD",
                },
                note: "note 21",
                user: "user 21",
                timestamp: "2022-03-22T14:26:40Z",
              },
              {
                genome: {
                  shopify_store_name: "butterlondon 21",
                  shopify_order_id: 467129126108,
                  shopify_order_price: 86.02,
                  sage_sales_site: "BUTCO",
                  sage_sales_order_no: "467129126108",
                  sage_order_reference: "41000007760",
                  sage_order_date: "2022-02-21T13:55:58-08:00",
                  sage_required_delivery_date: "2022-02-22T13:55:58-08:00",
                  sage_customer_number: "6999999",
                  sage_payment_method: "SHOPIFYC",
                  sage_delivery_method: "USPS",
                  sage_carrier: "USPS",
                  billing_address: {
                    FirstName: "Amy",
                    LastName: "Lemo",
                    Company: "",
                    AddressLine1: "617 West 5th Street",
                    AddressLine2: "",
                    AddressLine3: "",
                    City: "New York",
                    StateCode: "NY",
                    ZipCode: "10021",
                    CountryCode: "US",
                    Phone: null,
                  },
                  delivery_address: {
                    FirstName: "Amy",
                    LastName: "Lemo",
                    Company: "",
                    AddressLine1: "617 West 5th Street",
                    AddressLine2: "",
                    AddressLine3: "",
                    City: "New York",
                    StateCode: "NY",
                    ZipCode: "10021",
                    CountryCode: "US",
                    Phone: null,
                  },
                  sage_freight_amount: 0,
                  sage_discout_amount: 0,
                  sage_sales_tax_amount: 7.02,
                  sage_gift_card_amount: 20.65,
                  sage_line_items: [
                    {
                      Sku: "",
                      Description: "butter LONDON Ultimate Manicure Kit",
                      Quantity: 1,
                      Price: 79,
                    },
                  ],
                  sage_payment_bank: "1066",
                  sage_payment_entry_reference: "",
                  sage_payment_amount: 65.37,
                  sage_payment_date: "2022-02-21T13:55:52-08:00",
                  sage_payment_account: "11000",
                  sage_payment_currency: "USD",
                },
                note: "note 22",
                user: "user 22",
                timestamp: "2022-03-22T14:27:40Z",
              },
            ],
          }
        },
        { timing: 500 }
      )
      this.get(
        "http://localhost:1080/api/v1/plants/:id/genome/ui",
        (schema, request) => {
          return [
            "shopify_store_name",
            "shopify_order_id",
            "shopify_order_price",
            "sage_sales_site",
            "sage_sales_order_no",
            "sage_order_reference",
            {
              dataField: "sage_order_date",
              editorType: "dxDateBox",
              editorOptions: { type: "datetime" },
            },
            {
              dataField: "sage_required_delivery_date",
              editorType: "dxDateBox",
              editorOptions: { type: "datetime" },
            },
            "sage_customer_number",
            "sage_payment_method",
            "sage_delivery_method",
            "sage_carrier",
            {
              itemType: "group",
              caption: "Billing Address",
              items: [
                {
                  dataField: "billing_address",
                  template: "form",
                  editorType: "form",
                  name: "billingAddress",
                  editorOptions: {
                    formItems: [
                      "FirstName",
                      "LastName",
                      "Company",
                      "AddressLine1",
                      "AddressLine2",
                      "AddressLine3",
                      "City",
                      "StateCode",
                      "ZipCode",
                      "CountryCode",
                      "Phone",
                    ],
                  },
                },
              ],
            },

            {
              itemType: "group",
              caption: "Delivery Address",
              items: [
                {
                  dataField: "delivery_address",
                  template: "form",
                  editorType: "form",
                  name: "deliveryAddress",
                  test: "test",
                  editorOptions: {
                    formItems: [
                      "FirstName",
                      "LastName",
                      "Company",
                      "AddressLine1",
                      "AddressLine2",
                      "AddressLine3",
                      "City",
                      "StateCode",
                      "ZipCode",
                      "CountryCode",
                      "Phone",
                    ],
                  },
                },
              ],
            },
            "sage_freight_amount",
            "sage_discout_amount",
            "sage_sales_tax_amount",
            "sage_gift_card_amount",
            {
              itemType: "group",
              caption: "Sage Line Items",
              colSpan: 2,
              items: [
                {
                  dataField: "sage_line_items",
                  template: "dataGrid",
                  name: "sageLineItems",
                },
              ],
            },

            "sage_payment_bank",
            "sage_payment_entry_reference",
            "sage_payment_amount",
            {
              dataField: "sage_payment_date",
              editorType: "dxDateBox",
              editorOptions: { type: "datetime" },
            },
            "sage_payment_account",
            "sage_payment_currency",
          ]
        },
        { timing: 500 }
      )
      this.get(
        "http://localhost:1080/api/v1/shopify/plants/species",
        () => {
          return ["shopify_order", "shopify_inventory"]
        },
        { timing: 500 }
      )
      this.passthrough("http://localhost:8000/**")
      this.passthrough("http://localhost:1080/**")
    },
  })
  server.logging = true
  return server
}
